import React, { useEffect, useState } from 'react';
import './../../../App.css';
import { IconArrowBack, IconArrowBarRight, IconArrowDownToArc, IconArrowLeft, IconArrowRampLeft, IconArrowRight, IconArrowRightBar, IconArrowsLeft, IconArrowsRight, IconPencil, IconTrash, IconUserPlus } from '@tabler/icons-react';
import StoreApi from '../../../services/StoreApi';
import ClienteForm from './ClienteForm';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { FileUpload } from '../../../components/components/ui/File-upload';
import Cookies from 'universal-cookie';
import Pako from 'pako';

const cookies = new Cookies();

export default function Clientes() {

    const [clientes, setClientes] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [totalResultados, setTotalResultados] = useState(0);

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [selectedCliente, setSelectedCliente] = useState<any>(null);
    const [clienteToDelete, setClienteToDelete] = useState<any>(null);

    const [tempFilters, setTempFilters] = useState({
        nomeRazaoSocial: '',
        cpfCnpj: '',
        cidade: '',
        estado: '',
        status: '',
    });

    const [filtersUsed, setFiltersUsed] = useState({
        nomeRazaoSocial: { filterType: 'ILIKE', filterValue: '' },
        cpfCnpj: { filterType: 'ILIKE', filterValue: '' },
        cidade: { filterType: 'ILIKE', filterValue: '' },
        estado: { filterType: 'ILIKE', filterValue: '' },
    });

    const [tempOrder, setTempOrder] = useState({ nomeRazaoSocial: 'asc' });

    const [orderUsed, setOrderUsed] = useState({ nomeRazaoSocial: 'asc' });

    // Número da página atual
    const [currentPage, setCurrentPage] = useState(1);

    // Quantos itens por página
    const [perPage, setPerPage] = useState(6);

    // Total de páginas retornadas pelo backend
    const [lastPages, setLastPages] = useState(1);


    useEffect(() => {
        fetchClientes();
    }, [currentPage, filtersUsed, orderUsed]);
    const displayPages = getDisplayPages(currentPage, lastPages, 3);

    function buildFilters(tempFilters: any) {
        const filterParams: any = {};

        if (tempFilters.nomeRazaoSocial) {
            filterParams.nomeRazaoSocial = {
                filterType: 'ILIKE',
                filterValue: tempFilters.nomeRazaoSocial,
            };
        }

        if (tempFilters.cpfCnpj) {
            filterParams.cpfCnpj = {
                filterType: 'ILIKE',
                filterValue: tempFilters.cpfCnpj,
            };
        }

        if (tempFilters.cidade) {
            filterParams.cidade = {
                filterType: 'ILIKE',
                filterValue: tempFilters.cidade,
            };
        }

        if (tempFilters.estado) {
            filterParams.estado = {
                filterType: 'ILIKE',
                filterValue: tempFilters.estado,
            };
        }

        if (tempFilters.status) {
            filterParams.status = {
                filterType: 'EQUALS',
                filterValue: tempFilters.status,
            };
        }

        return filterParams;
    }

    function getDisplayPages(currentPage: any, totalPages: any, maxButtons = 6) {
        // Quantos botões mostrar à esquerda/direita da página atual
        const half = Math.floor(maxButtons / 2);

        // Calcula onde começa a sequência de páginas
        let start = Math.max(currentPage - half, 1);
        // Calcula onde termina a sequência de páginas
        let end = Math.min(start + maxButtons - 1, totalPages);

        // Ajusta o 'start' caso o 'end' esteja limitado no total de páginas
        if (end - start < maxButtons - 1) {
            start = Math.max(end - maxButtons + 1, 1);
        }

        // Cria o array com os números de página que serão exibidos
        const pages = [];
        for (let i = start; i <= end; i++) {
            pages.push(i);
        }

        return pages;
    }

    // Busca clientes no backend
    async function fetchClientes() {
        setLoading(true);

        const payload = {
            paginate: perPage,
            page: currentPage,
            filters: buildFilters(tempFilters),  // renomear p/ "filters"
            orderBy: tempOrder,                  // renomear p/ "orderBy"
        };

        await StoreApi.listCliente(payload).then((r) => {
            setClientes(r.data);
            console.log(r.total)
            setTotalResultados(r.total);
            setLastPages(r.last_page);
            setLoading(false);

        }).catch((error: any) => {
            console.error('Erro ao buscar clientes:', error);
        });


        await StoreApi.listCliente().then((response) => {
            const compressedData = compressAndEncode(response);
            cookies.set('clientes', compressedData);
        }).catch((error: any) => {
            console.error('Erro ao buscar clientes:', error);
        });

    }

    // Função para comprimir e codificar com Base64
    const compressAndEncode = (value: any) => {
        try {
            const jsonString = JSON.stringify(value);
            const compressed = Pako.deflate(jsonString);
            const binaryString = compressed.reduce((data, byte) => data + String.fromCharCode(byte), '');
            return btoa(binaryString);
        } catch (error) {
            console.error('Erro ao compactar valor:', error);
            return null;
        }
    };


    // ........................................................................
    // FILTROS E ORDENAÇÃO - EVENTOS
    // ........................................................................
    // Quando muda um input (sem buscar imediatamente)
    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setTempFilters((prev) => ({ ...prev, [name]: value }));
    };

    // Botão "Buscar": aplica os filtros e ordenação configurados
    const handleSearch = () => {
        // Monta o objeto do "filtersUsed"
        setFiltersUsed({
            nomeRazaoSocial: {
                filterType: 'ILIKE',
                filterValue: tempFilters.nomeRazaoSocial,
            },
            cpfCnpj: {
                filterType: 'ILIKE',
                filterValue: tempFilters.cpfCnpj,
            },
            cidade: {
                filterType: 'ILIKE',
                filterValue: tempFilters.cidade,
            },
            estado: {
                filterType: 'ILIKE',
                filterValue: tempFilters.estado,
            },
        });

        // Aplica a ordenação
        setOrderUsed(tempOrder);

        // Volta para a página 1 e busca
        setCurrentPage(1);
    };

    // Altera a ordenação localmente (sem buscar ainda)
    const handleSort = (column: string) => {
        setTempOrder((prev: any) => ({
            [column]: prev[column] === 'asc' ? 'desc' : 'asc',
        } as any));
    };

    // ........................................................................
    // PAGINAÇÃO - EVENTOS
    // ........................................................................
    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= lastPages) {
            setCurrentPage(newPage);
        }
    };

    // ........................................................................
    // CRUD - EVENTOS
    // ........................................................................
    const handleConfirmDelete = (cliente: any) => {
        setClienteToDelete(cliente);
        setDeleteModalOpen(true);
    };

    const handleDelete = async () => {
        if (!clienteToDelete) return;
        try {
            await StoreApi.deleteCliente(clienteToDelete.id);
            setDeleteModalOpen(false);
            setClienteToDelete(null);
            fetchClientes();
        } catch (error) {
            console.error('Erro ao deletar cliente:', error);
        }
    };

    const handleLoad = () => {
        fetchClientes();
        setIsAddModalOpen(false);
    };

    // ........................................................................
    // IMPORTAÇÃO
    // ........................................................................
    const handleFileUpload = async (files: File[]) => {
        try {
            await StoreApi.importarClientes(files[0]);
            setIsImportModalOpen(false);
            fetchClientes();
        } catch (error) {
            console.error('Erro ao importar clientes:', error);
        }
    };

    // ........................................................................
    // AUXILIAR - CORES DE STATUS
    // ........................................................................
    const getStatusColor = (status: string) => {
        switch (status) {
            case 'pendente':
                return 'bg-yellow-100 text-yellow-800';
            case 'em_preparacao':
                return 'bg-blue-100 text-blue-800';
            case 'saiu_para_entrega':
                return 'bg-orange-100 text-orange-800';
            case 'entregue':
                return 'bg-green-100 text-green-800';
            case 'cancelado':
                return 'bg-red-100 text-red-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    // ........................................................................
    // RENDERIZAÇÃO
    // ........................................................................
    return (
        <>
            <section className="flex w-full h-[90%] bg-white">
                <div className="flex-col items-center px-4 my-4 w-full h-[90%]">
                    <section className="bg-[#f3f4f6] w-full h-[100%] first-letter:px-4 py-2 p-4 rounded-md">
                        {/* CABEÇALHO */}
                        <div className="block sm:flex sm:items-center sm:justify-between">
                            <div>
                                <div className="flex items-center gap-3">
                                    <h2 className="text-2xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center dark:text-white">
                                        Clientes
                                    </h2>
                                    <span className="px-3 py-1 text-xs text-green-600 bg-green-100 rounded-full dark:bg-gray-800 dark:text-green-400">
                                        {totalResultados + ' Resultados'}
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center mt-4 gap-3">
                                {/* Botão Importar */}
                                <button
                                    className="flex items-center justify-center w-1/2 px-2 py-2 text-xs text-gray-700 bg-white border rounded-lg gap-x-1 sm:w-auto hover:bg-gray-100 dark:bg-gray-900 dark:hover:bg-gray-800 dark:text-gray-200 dark:border-gray-700"
                                    onClick={() => setIsImportModalOpen(true)}
                                >
                                    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
                                        <g clipPath="url(#clip0_3098_154395)">
                                            <path
                                                d="M13.3333 13.3332L9.99997 9.9999M9.99997 9.9999L6.66663 13.3332M9.99997 9.9999V17.4999M16.9916 15.3249C17.8044 14.8818 18.4465 14.1806 18.8165 13.3321C19.1866 12.4835 19.2635 11.5359 19.0351 10.6388C18.8068 9.7417 18.2862 8.94616 17.5555 8.37778C16.8248 7.80939 15.9257 7.50052 15 7.4999H13.95C13.6977 6.52427 13.2276 5.61852 12.5749 4.85073C11.9222 4.08295 11.104 3.47311 10.1817 3.06708C9.25943 2.66104 8.25709 2.46937 7.25006 2.50647C6.24304 2.54358 5.25752 2.80849 4.36761 3.28129C3.47771 3.7541 2.70656 4.42249 2.11215 5.23622C1.51774 6.04996 1.11554 6.98785 0.935783 7.9794C0.756025 8.97095 0.803388 9.99035 1.07431 10.961C1.34523 11.9316 1.83267 12.8281 2.49997 13.5832"
                                                stroke="currentColor"
                                                strokeWidth="1.67"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3098_154395">
                                                <rect width={20} height={20} fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span>Importar</span>
                                </button>
                                {/* Botão Adicionar Cliente */}
                                <button
                                    className="flex items-center justify-center w-1/2 px-2 py-2 text-xs tracking-wide text-white bg-green-500 rounded-lg shrink-0 sm:w-auto gap-x-1 hover:bg-green-600 dark:hover:bg-green-500 dark:bg-green-600"
                                    onClick={() => {
                                        setSelectedCliente(null);
                                        setIsAddModalOpen(true);
                                    }}
                                >
                                    <span>Adicionar Cliente</span>
                                </button>
                            </div>
                        </div>

                        <hr className='w-full h-[2px] shadow-xl bg-green-300 m-1' />
                        {/* FILTROS & BOTÃO "BUSCAR" */}
                        {!loading &&
                            <div className="flex gap-4 mt-2 py-1 overflow-auto">
                                <input
                                    type="text"
                                    name="nomeRazaoSocial"
                                    value={tempFilters.nomeRazaoSocial}
                                    onChange={handleFilterChange}
                                    placeholder="Nome"
                                    className="border rounded-md p-2"
                                />
                                <input
                                    type="text"
                                    name="cpfCnpj"
                                    value={tempFilters.cpfCnpj}
                                    onChange={handleFilterChange}
                                    placeholder="CPF/CNPJ"
                                    className="border rounded-md p-2"
                                />
                                <input
                                    type="text"
                                    name="cidade"
                                    value={tempFilters.cidade}
                                    onChange={handleFilterChange}
                                    placeholder="Cidade"
                                    className="border rounded-md p-2"
                                />
                                <input
                                    type="text"
                                    name="estado"
                                    value={tempFilters.estado}
                                    onChange={handleFilterChange}
                                    placeholder="Estado"
                                    className="border rounded-md p-2"
                                />
                                {/*  <select
                                name="status"
                                value={tempFilters.status}
                                onChange={handleFilterChange}
                                className="border rounded-md p-2"
                            >
                                <option value="">Status</option>
                                <option value="pendente">Pendente</option>
                                <option value="em_preparacao">Em preparação</option>
                                <option value="saiu_para_entrega">Saiu para entrega</option>
                                <option value="entregue">Entregue</option>
                                <option value="cancelado">Cancelado</option>
                            </select> */}
                                <button
                                    onClick={handleSearch}
                                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                                >
                                    Buscar
                                </button>
                            </div>
                        }
                        {isAddModalOpen && (
                            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm">
                                <div className="w-full block bg-gray-100 rounded-lg">
                                    <div className="sticky top-0 h-full bg-gray-100 p-2 flex items-center justify-between rounded-lg">
                                        <button
                                            className="flex items-center w-full text-gray-400 hover:text-gray-600 rounded-lg"
                                            onClick={() => { setSelectedCliente({}); setIsAddModalOpen(false) }}
                                        >
                                            <ChevronLeftIcon className="h-6 w-6" />
                                            <span className="ml-2">Voltar</span>
                                        </button>
                                    </div>
                                    <div className='w-100 bg-white mb-4 px-2 my-72 h-screen md:h-auto md:my-2 lg:h-auto lg:my-2 '>
                                        {selectedCliente ?
                                            <>
                                                <ClienteForm data={selectedCliente} onSuccess={handleLoad} />
                                            </>
                                            :
                                            <>
                                                <ClienteForm onSuccess={handleLoad} />
                                            </>
                                        }
                                    </div>

                                </div>
                            </div>
                        )}
                        {deleteModalOpen && (
                            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
                                <div className="bg-white rounded-lg p-6">
                                    <h2 className="text-lg font-medium mb-4">
                                        Tem certeza que deseja deletar este cliente?
                                    </h2>
                                    <div className="flex justify-end gap-4">
                                        <button
                                            className="bg-red-500 text-white px-4 py-2 rounded"
                                            onClick={handleDelete}
                                        >
                                            Deletar
                                        </button>
                                        <button
                                            className="bg-gray-200 px-4 py-2 rounded"
                                            onClick={() => { setSelectedCliente({}); setDeleteModalOpen(false) }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isImportModalOpen && (
                            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm">

                                <div className=" bg-white rounded-lg m-10">
                                    <div className="sticky top-0 h-full bg-gray-100 p-2 flex items-center justify-between rounded-lg">
                                        <button
                                            className="flex items-center w-full text-gray-400 hover:text-gray-600 rounded-lg"
                                            onClick={() => setIsImportModalOpen(false)}
                                        >
                                            <ChevronLeftIcon className="h-6 w-6" />
                                            <span className="ml-2">Voltar</span>
                                        </button>
                                    </div>
                                    <h3 className="text-lg font-medium p-2 ml-4">Importar Clientes</h3>
                                    <div className="sm:flex-auto mx-10">
                                        <p className="mt-2 text-xs text-center text-gray-700">
                                            Importe um csv com a lista de seus clientes, e economize tempo.
                                        </p>
                                    </div>
                                    <div className=' p-2'>
                                        {/* Botão de Upload de CSV */}
                                        <div className="w-full h-full max-w-4xl py-10 border border-dashed border-neutral-200 dark:border-neutral-800 rounded-lg">
                                            <FileUpload onChange={handleFileUpload} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}

                        {loading ? (
                            <>
                                <div className="animate-pulse">
                                    {/* Cabeçalho Skeleton */}
                                    <div className="flex justify-between items-center mb-4">
                                        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
                                        <div className="flex gap-3">
                                            <div className="h-8 bg-gray-200 rounded w-20"></div>
                                            <div className="h-8 bg-gray-200 rounded w-32"></div>
                                        </div>
                                    </div>

                                    {/* Filtros Skeleton */}
                                    <div className="flex gap-4 mb-6">
                                        {[...Array(5)].map((_, i) => (
                                            <div key={i} className="h-10 bg-gray-200 rounded w-full"></div>
                                        ))}
                                    </div>

                                    {/* Tabela Skeleton */}
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    {[...Array(6)].map((_, i) => (
                                                        <th key={i} className="px-4 py-3">
                                                            <div className="h-4 bg-gray-200 rounded w-3/4 mx-auto"></div>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {[...Array(3)].map((_, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {[...Array(6)].map((_, cellIndex) => (
                                                            <td key={cellIndex} className="px-4 py-4">
                                                                <div className="h-4 bg-gray-200 rounded w-3/4 mx-auto"></div>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    {/* Paginação Skeleton */}
                                    <div className="flex justify-center mt-6">
                                        <div className="flex gap-2">
                                            {[...Array(2)].map((_, i) => (
                                                <div key={i} className="h-8 bg-gray-200 rounded w-8"></div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : clientes.length === 0 && !loading ?
                            <div className="flex mx-4 items-center mt-6 text-center border rounded-lg h-96 dark:border-gray-700">
                                <div className="flex flex-col w-full max-w-sm px-4 mx-auto">
                                    <div className='flex items-center justify-center text-center'>
                                        <button
                                            type="button"
                                            onClick={() => setIsAddModalOpen(true)}
                                            className="relative mt-2 flex flex-col items-center justify-center w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                        >
                                            <IconUserPlus className='w-10 h-10' />
                                            <span className="mt-2 block text-sm font-semibold text-gray-900">Adicionar Cliente</span>
                                        </button>
                                    </div>
                                    <p className="mt-2 text-xs text-center text-gray-700">
                                        Nenhum resultado encontrado...
                                    </p>
                                </div>
                            </div>
                            :
                            <section className="mb-1">
                                <div className="overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                        <thead className="bg-gray-50 dark:bg-gray-800">
                                            <tr>
                                                {[
                                                    'Nome',
                                                    'CPF/CNPJ',
                                                    'Celular',
                                                    'Email',
                                                    'Cidade',
                                                    'Estado',
                                                    'Total Pedidos',
                                                    'Último Pedido',
                                                ].map((col) => (
                                                    <th
                                                        key={col}
                                                        className="p-2 text-center cursor-pointer bg-gray-200"
                                                        onClick={() => handleSort(col)}
                                                    >
                                                        {col}
                                                    </th>
                                                ))}
                                                <th className="p-2 bg-gray-200">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                            {clientes.map((c: any) => (
                                                <tr key={c.id}>
                                                    {/* Nome */}
                                                    <td className="px-4 py-2 text-sm text-center font-medium text-gray-700 whitespace-nowrap">
                                                        {c.nomeRazaoSocial}
                                                    </td>
                                                    {/* CPF/CNPJ */}
                                                    <td className="px-4 py-2 text-sm text-center text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                        {c.cpfCnpj}
                                                    </td>
                                                    {/* Celular */}
                                                    <td className="px-4 py-2 text-sm text-center text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                        {c.celular || '-'}
                                                    </td>
                                                    {/* Email */}
                                                    <td className="px-4 py-2 text-sm text-center text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                        {c.email}
                                                    </td>
                                                    {/* Cidade */}
                                                    <td className="px-4 py-2 text-sm text-center text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                        {c.cidade}
                                                    </td>
                                                    {/* Estado */}
                                                    <td className="px-4 py-2 text-sm text-center text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                        {c.estado}
                                                    </td>
                                                    {/* Total de Pedidos */}
                                                    <td className="px-4 py-2 text-sm text-center text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                                        {c.pedidos ? c.pedidos.length : 0}
                                                    </td>
                                                    {/* Status do Pedido Mais Recente */}
                                                    <td className="px-4 py-2 text-center text-sm">
                                                        {c.pedidos && c.pedidos.length > 0 ? (
                                                        
                                                                <span className={`whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md ${c.pedidos[c.pedidos.length - 1].status === "pendente" ? "text-yellow-700 bg-yellow-50" :
                                                                    c.pedidos[c.pedidos.length - 1].status === "cancelado" ? "text-red-700 bg-red-50" :
                                                                      c.pedidos[c.pedidos.length - 1].status === "entregue" ? "text-green-700 bg-green-50" :
                                                                        c.pedidos[c.pedidos.length - 1].status === "em_preparacao" ? "text-yellow-700 bg-yellow-50" :
                                                                          c.pedidos[c.pedidos.length - 1].status === "saiu_para_entrega" ? "text-yellow-700 bg-yellow-50" :
                                                                            "text-red-700 bg-red-50"}`}>
                                                                    {c.pedidos[c.pedidos.length - 1].status === "pendente" ? "Aguardando Confirmação" :
                                                                      c.pedidos[c.pedidos.length - 1].status === "entregue" ? "Entregue" :
                                                                        c.pedidos[c.pedidos.length - 1].status === "em_preparacao" ? "Em Preparação" :
                                                                          c.pedidos[c.pedidos.length - 1].status === "saiu_para_entrega" ? "Saiu para Entrega" : "Cancelado"}
                                                                  </span>
                                                        ) : (
                                                            <span className="text-gray-400">
                                                                Sem pedidos
                                                            </span>
                                                        )}
                                                    </td>
                                                    {/* Ações */}
                                                    <td className="px-4 py-2 text-sm text-center whitespace-nowrap">
                                                        <div className="flex items-center gap-x-6 justify-center">
                                                            {/* Excluir */}
                                                            <button
                                                                className="text-gray-500 hover:text-red-500"
                                                                onClick={() => handleConfirmDelete(c)}
                                                            >
                                                                <IconTrash className="h-6 w-6" />
                                                            </button>
                                                            {/* Editar */}
                                                            <button
                                                                className="text-gray-500 hover:text-blue-500"
                                                                onClick={() => {
                                                                    setSelectedCliente(c);
                                                                    setIsAddModalOpen(true);
                                                                }}
                                                            >
                                                                <IconPencil className="h-6 w-6" />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex flex-col items-center mt-4 space-y-2">
                                    {/* Informações de total de páginas e registros */}
                                    <div className="flex items-center space-x-2">

                                        {/* Botão para primeira página */}
                                        <button
                                            onClick={() => handlePageChange(1)}
                                            disabled={currentPage === 1}
                                            title="Primeira página"
                                            className={`
                                                    flex items-center justify-center w-8 h-8 rounded-full transition-colors duration-200 
                                                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 
                                                    ${currentPage === 1
                                                    ? 'bg-gray-300 cursor-not-allowed opacity-50'
                                                    : 'bg-gray-600 text-white hover:bg-gray-700'
                                                }
                                                `}
                                        >
                                            <IconArrowsLeft className="w-4 h-4" />
                                        </button>

                                        {/* Botão para página anterior */}
                                        <button
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                            title="Página anterior"
                                            className={`
                                                flex items-center justify-center w-8 h-8 rounded-full transition-colors duration-200 
                                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 
                                                ${currentPage === 1
                                                    ? 'bg-gray-300 cursor-not-allowed opacity-50'
                                                    : 'bg-gray-600 text-white hover:bg-gray-700'
                                                }
                                            `}
                                        >
                                            <IconArrowLeft className="w-4 h-4" />
                                        </button>

                                        {/* Botões numéricos das páginas */}
                                        {displayPages.map((pageNumber) => (
                                            <button
                                                key={pageNumber}
                                                onClick={() => handlePageChange(pageNumber)}
                                                className={`
                                                    px-3 py-1 rounded transition-colors duration-200 
                                                    focus:outline-none focus:ring-2 focus:ring-offset-2 
                                                    ${currentPage === pageNumber
                                                        ? 'bg-blue-600 text-white focus:ring-blue-600'
                                                        : 'bg-gray-200 hover:bg-gray-300 focus:ring-gray-300'
                                                    }
                                                    `}
                                            >
                                                {pageNumber}
                                            </button>
                                        ))}

                                        {/* Botão para próxima página */}
                                        <button
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === lastPages}
                                            title="Próxima página"
                                            className={`
                                            flex items-center justify-center w-8 h-8 rounded-full transition-colors duration-200 
                                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 
                                            ${currentPage === lastPages
                                                    ? 'bg-gray-300 cursor-not-allowed opacity-50'
                                                    : 'bg-gray-600 text-white hover:bg-gray-700'
                                                }
                                        `}
                                        >
                                            <IconArrowRight className="w-4 h-4" />
                                        </button>

                                        {/* Botão para última página */}
                                        <button
                                            onClick={() => handlePageChange(lastPages)}
                                            disabled={currentPage === lastPages}
                                            title="Última página"
                                            className={`
                                                flex items-center justify-center w-8 h-8 rounded-full transition-colors duration-200 
                                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600
                                                ${currentPage === lastPages
                                                    ? 'bg-gray-300 cursor-not-allowed opacity-50'
                                                    : 'bg-gray-600 text-white hover:bg-gray-700'
                                                }
                                            `}
                                        >
                                            <IconArrowsRight className="w-4 h-4" />
                                        </button>
                                    </div>

                                </div> <span className="text-xs text-end">
                                    Página {currentPage} de {lastPages}
                                </span>

                            </section>
                        }
                    </section>
                </div>
            </section>{console.log(lastPages, currentPage)}
        </>
    );
}
