import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
/* 
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"; */

function Payment() {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedPlan, setSelectedPlan] = useState({
        name: 'Equipe',
        price: 299,
        type: 'monthly'
    });
    const [coupon, setCoupon] = useState('');
    const [discount, setDiscount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('credit_card');
    const [cardData, setCardData] = useState({
        name: '',
        number: '',
        expiryMonth: '01',
        expiryYear: '2024',
        cvv: ''
    });

    // Obter dados do plano da navegação
    useEffect(() => {
        if (location.state?.plan) {
            setSelectedPlan(location.state.plan);
        }
    }, [location.state]);

    // Calcular total com desconto
    const calculateTotal = () => {
        return selectedPlan.price - discount;
    };

    // Aplicar cupom de desconto
    const applyCoupon = () => {
        // Aqui você pode implementar a lógica de validação do cupom
        // Exemplo simples com cupom fixo
        if (coupon.toUpperCase() === 'DESC10') {
            setDiscount(selectedPlan.price * 0.1); // 10% de desconto
            alert('Cupom aplicado com sucesso!');
        } else if (coupon) {
            alert('Cupom inválido');
        }
    };

    // Processar pagamento com iPag (simulação)
    const processIPagPayment = async () => {
        try {
            // Aqui você faria a chamada para a API do iPag
            // Esta é uma simulação:
            const response = await fetch('https://api.ipag.com.br/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer SUA_CHAVE_API_IPAG'
                },
                body: JSON.stringify({
                    amount: calculateTotal(),
                    payment_method: paymentMethod,
                    card: cardData,
                    plan: selectedPlan.name,
                    billing_type: selectedPlan.type
                })
            });

            const data = await response.json();
            
            if (data.success) {
                navigate('/success', { state: { plan: selectedPlan } });
            } else {
                alert('Pagamento falhou: ' + data.message);
            }
        } catch (error) {
            console.error('Erro no pagamento:', error);
            alert('Erro ao processar pagamento');
        }
    };

    // Aprovação do PayPal
    const createPayPalOrder = (data : any, actions : any) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: calculateTotal().toFixed(2),
                        currency_code: "BRL"
                    },
                    description: `Plano ${selectedPlan.name} (${selectedPlan.type})`
                }
            ]
        });
    };

    // Sucesso no PayPal
    const onPayPalApprove = (data : any, actions : any) => {
        return actions.order.capture().then((details : any) => {
            // Aqui você pode registrar o pagamento no seu backend
            console.log('Pagamento concluído:', details);
            navigate('/success', { state: { plan: selectedPlan, paymentDetails: details } });
        });
    };

    return (
        <div className="h-screen">
            <div className="relative mx-auto w-screen" style={{ background: "#dad3cc" }}>
                <div className="absolute inset-0 bottom-20 bg-gradient-to-t from-green-900 to-green-700 border-green-700" />
                <div className="relative mx-auto w-full max-w-screen-xl px-4 pt-6 sm:px-6">
                    {/* Breadcrumb Navigation */}
                    <nav className="text-xs sm:text-sm text-white">
                        <ol className="list-none p-0 inline-flex space-x-2">
                            <li className="flex items-center">
                                <svg
                                    onClick={() => navigate('/')}
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 576 512"
                                    className="cursor-pointer hover:fill-green-500 transition-colors duration-300"
                                    fill="white"
                                >
                                    <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                                </svg>
                                <svg
                                    className="w-6 h-6 text-white"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </li>
                            <li className="flex items-center">
                                <button
                                    onClick={() => navigate("/pricing")}
                                    className="text-white hover:text-green-500 transition-colors duration-300"
                                >
                                    Planos
                                </button>
                                <svg
                                    className="w-6 h-6 text-white"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </li>
                            <li className="flex items-center">
                                <span className="text-white">Pagamento</span>
                            </li>
                        </ol>
                    </nav>

                    {/* Progress Steps */}
                    <div className="mx-auto w-full max-w-md pb-10 px-8 sm:px-0">
                        <div className="relative mt-5">
                            <div className="absolute left-0 top-2 h-0.5 w-full bg-gray-200">
                                <div className="absolute h-full w-2/3 bg-gray-900" />
                                <div className="left absolute left-2/3 h-full w-1/3 bg-gradient-to-r from-gray-900" />
                            </div>
                            <ul className="relative flex w-full justify-between">
                                {[1, 2, 3].map((step) => (
                                    <li key={step}>
                                        <div className={`flex h-5 w-5 items-center justify-center rounded-full text-xs font-semibold 
                                            ${step === 3 ? 'bg-gray-300 text-white ring ring-green-600 ring-offset-2' : 'bg-green-400 text-white'}`}>
                                            {step}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    {/* Payment Form */}
                    <div className="w-full bg-white rounded-md border-t border-b border-gray-200 px-5 py-10 text-gray-800">
                        <div className="w-full">
                            <div className="-mx-3 md:flex items-start">
                                {/* Order Summary */}
                                <div className="px-3 md:w-7/12 lg:pr-10">
                                    <div className="w-full mx-auto text-gray-800 font-light mb-6 border-b border-gray-200 pb-6">
                                        <div className="w-full flex items-center">
                                            <div className="flex-grow pl-3">
                                                <h6 className="font-semibold text-gray-600">
                                                    PLANO {selectedPlan.name.toUpperCase()} 
                                                    <span className="font-semibold text-gray-400"> / {selectedPlan.type === 'monthly' ? 'Mês' : 'Ano'}</span>
                                                </h6>
                                            </div>
                                            <div>
                                                <span className="font-semibold text-gray-600 text-xl">
                                                    R$ {selectedPlan.price.toFixed(2)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Coupon Field */}
                                    <div className="mb-2 pb-2 border-b border-gray-200">
                                        <div className="-mx-2 flex items-end justify-end">
                                            <div className="flex-grow px-2 lg:max-w-xs">
                                                <label className="text-gray-600 font-semibold text-sm mb-2 ml-1">
                                                    Cupom
                                                </label>
                                                <input
                                                    className="w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                    placeholder="XXXXXX"
                                                    type="text"
                                                    value={coupon}
                                                    onChange={(e) => setCoupon(e.target.value)}
                                                />
                                            </div>
                                            <div className="px-2">
                                                <button 
                                                    onClick={applyCoupon}
                                                    className="block w-full max-w-xs mx-auto border border-transparent bg-green-400 hover:bg-green-500 focus:bg-gray-500 text-white rounded-md px-5 py-2 uppercase font-semibold"
                                                >
                                                    Aplicar
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Order Totals */}
                                    <div className="border-b border-gray-200 text-gray-800">
                                        <div className="w-full flex mb-3 items-center">
                                            <div className="flex-grow">
                                                <span className="text-gray-600">Subtotal</span>
                                            </div>
                                            <div className="pl-3">
                                                <span className="font-semibold">R$ {selectedPlan.price.toFixed(2)}</span>
                                            </div>
                                        </div>
                                        {discount > 0 && (
                                            <div className="w-full flex mb-3 items-center">
                                                <div className="flex-grow">
                                                    <span className="text-gray-600">Desconto</span>
                                                </div>
                                                <div className="pl-3">
                                                    <span className="font-semibold text-green-600">- R$ {discount.toFixed(2)}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-2 pb-2 border-b border-gray-200 md:border-none text-gray-800 text-xl">
                                        <div className="w-full flex items-center">
                                            <div className="flex-grow">
                                                <span className="text-gray-600">Total</span>
                                            </div>
                                            <div className="pl-3">
                                                <span className="font-semibold text-gray-400 text-sm">BRL</span>
                                                <span className="font-semibold ml-2">R${calculateTotal().toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Payment Methods */}
                                <div className="px-3 md:w-5/12">
                                    <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 text-gray-800 font-light mb-6">
                                        {/* Credit Card Option */}
                                        <div className={`w-full p-3 ${paymentMethod === 'credit_card' ? 'border-b border-gray-200' : ''}`}>
                                            <label className="flex items-center cursor-pointer">
                                                <input
                                                    type="radio"
                                                    className="form-radio h-5 w-5 text-indigo-500"
                                                    name="paymentMethod"
                                                    checked={paymentMethod === 'credit_card'}
                                                    onChange={() => setPaymentMethod('credit_card')}
                                                />
                                                <img
                                                    src="https://leadershipmemphis.org/wp-content/uploads/2020/08/780370.png"
                                                    className="h-6 ml-3"
                                                    alt="Cartão de Crédito"
                                                />
                                            </label>

                                            {paymentMethod === 'credit_card' && (
                                                <div className="mt-4">
                                                    <div className="mb-3">
                                                        <label className="text-gray-600 font-semibold text-sm mb-2 ml-1">
                                                            Nome no cartão
                                                        </label>
                                                        <input
                                                            className="w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                            placeholder="Nome no cartão"
                                                            type="text"
                                                            value={cardData.name}
                                                            onChange={(e) => setCardData({...cardData, name: e.target.value})}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="text-gray-600 font-semibold text-sm mb-2 ml-1">
                                                            Número do cartão
                                                        </label>
                                                        <input
                                                            className="w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                            placeholder="0000 0000 0000 0000"
                                                            type="text"
                                                            value={cardData.number}
                                                            onChange={(e) => setCardData({...cardData, number: e.target.value})}
                                                        />
                                                    </div>
                                                    <div className="mb-3 -mx-2 flex items-end">
                                                        <div className="px-2 w-1/4">
                                                            <label className="text-gray-600 font-semibold text-sm mb-2 ml-1 whitespace-nowrap">
                                                                Mês
                                                            </label>
                                                            <select 
                                                                className="form-select w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer"
                                                                value={cardData.expiryMonth}
                                                                onChange={(e) => setCardData({...cardData, expiryMonth: e.target.value})}
                                                            >
                                                                {Array.from({length: 12}, (_, i) => 
                                                                    <option key={i+1} value={(i+1).toString().padStart(2, '0')}>
                                                                        {(i+1).toString().padStart(2, '0')}
                                                                    </option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div className="px-2 w-1/4">
                                                            <label className="text-gray-600 font-semibold text-sm mb-2 ml-1 whitespace-nowrap">
                                                                Ano
                                                            </label>
                                                            <select 
                                                                className="form-select w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer"
                                                                value={cardData.expiryYear}
                                                                onChange={(e) => setCardData({...cardData, expiryYear: e.target.value})}
                                                            >
                                                                {Array.from({length: 10}, (_, i) => 
                                                                    <option key={i} value={2024 + i}>
                                                                        {2024 + i}
                                                                    </option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div className="px-2 w-1/4">
                                                            <label className="text-gray-600 font-semibold text-sm mb-2 ml-1 whitespace-nowrap">
                                                                CVV
                                                            </label>
                                                            <input
                                                                className="w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                                type="text"
                                                                value={cardData.cvv}
                                                                onChange={(e) => setCardData({...cardData, cvv: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* PayPal Option */}
                                        <div className="w-full p-3">
                                            <label className="flex items-center cursor-pointer">
                                                <input
                                                    type="radio"
                                                    className="form-radio h-5 w-5 text-indigo-500"
                                                    name="paymentMethod"
                                                    checked={paymentMethod === 'paypal'}
                                                    onChange={() => setPaymentMethod('paypal')}
                                                />
                                                <img
                                                    src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                                                    width={80}
                                                    className="ml-3"
                                                    alt="PayPal"
                                                />
                                            </label>

                                            {paymentMethod === 'paypal' && (
                                                <div className="mt-4">
                                                {/*     <PayPalScriptProvider options={{ 
                                                        "client-id": "SEU_CLIENT_ID_PAYPAL",
                                                        currency: "BRL"
                                                    }}>
                                                        <PayPalButtons
                                                            style={{ layout: "vertical" }}
                                                            createOrder={createPayPalOrder}
                                                            onApprove={onPayPalApprove}
                                                        />
                                                    </PayPalScriptProvider> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Submit Button for Credit Card */}
                                    {paymentMethod === 'credit_card' && (
                                        <div>
                                            <button 
                                                onClick={processIPagPayment}
                                                className="block w-full max-w-xs mx-auto bg-green-500 hover:bg-green-400 focus:bg-green-700 text-white rounded-lg px-3 py-2 font-semibold"
                                            >
                                                PAGAR AGORA
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="p-5">
                        <div className="text-center text-gray-400 text-sm">
                            <span>Problemas com o pagamento? </span>
                            <button className="focus:outline-none underline text-gray-400">
                                Entre em contato
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment;