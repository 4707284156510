import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface PaymentUserProps {
  paymentData: {
    cardNumber: string;
    cardName: string;
    expiryDate: string;
    cvv: string;
    plan: string;
    autoRenewal: boolean;
    nextPaymentDate?: string;
  };
  isEditing: boolean;
  isLoading?: boolean;
  onEditToggle: () => void;
  onSave: (updatedData: {
    paymentInfo: {
      cardNumber: string;
      cardName: string;
      expiryDate: string;
      cvv: string;
    };
    plan: string;
    autoRenewal: boolean;
  }) => Promise<void>;
  onPaymentChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPlanChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onAutoRenewalToggle: () => void;
}

const PaymentUser: React.FC<PaymentUserProps> = ({
  paymentData,
  isEditing,
  isLoading = false,
  onEditToggle,
  onSave,
  onPaymentChange,
  onPlanChange,
  onAutoRenewalToggle
}) => {
  const [errors, setErrors] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  });

  const formatCardNumber = (number: string) => {
    if (!number) return '•••• •••• •••• ••••';
    return number.replace(/(\d{4})(?=\d)/g, '$1 ').slice(0, 19);
  };

  const formatExpiryDate = (date: string) => {
    if (!date) return 'MM/AA';
    const cleaned = date.replace(/\D/g, '');
    if (cleaned.length >= 3) {
      return `${cleaned.slice(0, 2)}/${cleaned.slice(2, 4)}`;
    }
    return cleaned;
  };

  const validateFields = () => {
    const newErrors = {
      cardNumber: paymentData.cardNumber.length !== 16 ? 'Número inválido' : '',
      expiryDate: paymentData.expiryDate.length !== 4 ? 'Data inválida' : '',
      cvv: paymentData.cvv.length !== 3 ? 'CVV inválido' : ''
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error !== '');
  };

  useEffect(() => {
    if (isEditing) validateFields();
  }, [paymentData, isEditing]);

  const handleSave = async () => {
    if (!validateFields()) return;

    try {
      await onSave({
        paymentInfo: {
          cardNumber: paymentData.cardNumber,
          cardName: paymentData.cardName,
          expiryDate: `${paymentData.expiryDate.slice(0, 2)}/${paymentData.expiryDate.slice(2)}`,
          cvv: paymentData.cvv
        },
        plan: paymentData.plan,
        autoRenewal: paymentData.autoRenewal
      });
    } catch (error) {
      console.error('Erro ao salvar dados de pagamento:', error);
      toast.error('Erro ao salvar dados de pagamento');
    }
  };

  const handlePaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let processedValue = value;

    switch (name) {
      case 'cardNumber':
        processedValue = value.replace(/\D/g, '').slice(0, 16);
        break;
      case 'expiryDate':
        processedValue = value.replace(/\D/g, '').slice(0, 4);
        break;
      case 'cvv':
        processedValue = value.replace(/\D/g, '').slice(0, 3);
        break;
    }

    onPaymentChange({
      ...e,
      target: {
        ...e.target,
        name,
        value: processedValue
      }
    });
  };

  return (
    <div className="p-4 bg-white border-green-300 rounded-lg border shadow-md dark:bg-gray-800 dark:border-gray-700 mt-2">
      <h3 className="text-xl font-semibold">Pagamento e Plano</h3>
      
      <div className="mt-4">
        <div className="flex items-center mb-4">
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={paymentData.autoRenewal}
              onChange={onAutoRenewalToggle}
              disabled={isLoading}
              className="sr-only peer"
            />
            <div className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-gray-50 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${paymentData.autoRenewal ? 'bg-blue-600' : 'bg-gray-200'}`}></div>
            <span className="ms-3 text-sm font-medium text-gray-900">
              Renovação Automática {paymentData.autoRenewal ? 'Ativada' : 'Desativada'}
            </span>
          </label>
        </div>

        <div className="max-w-xs md:max-w-md mt-4 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-xl overflow-hidden shadow-2xl">
          <div className="p-6">
            {isEditing ? (
              <div className="space-y-4">
                <div>
                  <label className="block text-xs font-light mb-1">Titular do Cartão</label>
                  <input
                    type="text"
                    name="cardName"
                    value={paymentData.cardName}
                    onChange={handlePaymentChange}
                    className="w-full bg-blue-400 bg-opacity-30 border border-blue-300 rounded px-3 py-2 text-white placeholder-blue-200"
                    placeholder="NOME DO TITULAR"
                    autoComplete="cc-name"
                    disabled={isLoading}
                  />
                </div>

                <div>
                  <label className="block text-xs font-light mb-1">Número do Cartão</label>
                  <input
                    type="text"
                    name="cardNumber"
                    value={formatCardNumber(paymentData.cardNumber)}
                    onChange={handlePaymentChange}
                    className="w-full bg-blue-400 bg-opacity-30 border border-blue-300 rounded px-3 py-2 text-white placeholder-blue-200"
                    placeholder="0000 0000 0000 0000"
                    autoComplete="cc-number"
                    disabled={isLoading}
                  />
                  {errors.cardNumber && <span className="text-red-200 text-xs">{errors.cardNumber}</span>}
                </div>

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <label className="block text-xs font-light mb-1">Validade</label>
                    <input
                      type="text"
                      name="expiryDate"
                      value={formatExpiryDate(paymentData.expiryDate)}
                      onChange={handlePaymentChange}
                      className="w-full bg-blue-400 bg-opacity-30 border border-blue-300 rounded px-3 py-2 text-white placeholder-blue-200"
                      placeholder="MM/AA"
                      autoComplete="cc-exp"
                      disabled={isLoading}
                    />
                    {errors.expiryDate && <span className="text-red-200 text-xs">{errors.expiryDate}</span>}
                  </div>
                  <div className="flex-1">
                    <label className="block text-xs font-light mb-1">CVV</label>
                    <input
                      type="password"
                      name="cvv"
                      value={paymentData.cvv}
                      onChange={handlePaymentChange}
                      className="w-full bg-blue-400 bg-opacity-30 border border-blue-300 rounded px-3 py-2 text-white placeholder-blue-200"
                      placeholder="•••"
                      autoComplete="cc-csc"
                      disabled={isLoading}
                    />
                    {errors.cvv && <span className="text-red-200 text-xs">{errors.cvv}</span>}
                  </div>
                </div>

                <div className="mt-6 flex space-x-3">
                  <button
                    type="button"
                    onClick={handleSave}
                    disabled={isLoading || Object.values(errors).some(error => error !== '')}
                    className={`py-2 px-4 text-sm font-medium text-white rounded-lg focus:ring-4 ${
                      isLoading || Object.values(errors).some(error => error !== '') 
                        ? 'bg-green-400 cursor-not-allowed' 
                        : 'bg-green-500 hover:bg-green-600 focus:ring-green-300'
                    }`}
                  >
                    {isLoading ? 'Salvando...' : 'Salvar Alterações'}
                  </button>
                  <button
                    type="button"
                    onClick={onEditToggle}
                    disabled={isLoading}
                    className="py-2 px-4 text-sm font-medium text-gray-900 bg-gray-50 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-primary-300 disabled:opacity-50"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-light text-sm">Titular do Cartão</p>
                    <p className="font-medium tracking-widest">
                      {paymentData.cardName || 'NOME DO TITULAR'}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="font-light text-sm">Plano Atual</p>
                    <p className="font-medium">{paymentData.plan}</p>
                  </div>
                </div>

                <div className="pt-6">
                  <p className="font-light text-sm">Número do Cartão</p>
                  <p className="font-medium tracking-more-wider">
                    {formatCardNumber(paymentData.cardNumber)}
                  </p>
                </div>

                <div className="pt-6">
                  <div className="flex justify-between">
                    <div>
                      <p className="font-light text-xs">Validade</p>
                      <p className="font-medium tracking-wider text-sm">
                        {formatExpiryDate(paymentData.expiryDate)}
                      </p>
                    </div>
                    <div>
                      <p className="font-light text-xs">CVV</p>
                      <p className="font-bold tracking-more-wider text-sm">•••</p>
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <button
                    type="button"
                    onClick={onEditToggle}
                    disabled={isLoading}
                    className={`w-full py-2 text-sm font-medium text-white rounded-lg focus:ring-4 ${
                      isLoading 
                        ? 'bg-blue-400 cursor-not-allowed' 
                        : 'bg-blue-400 hover:bg-blue-500 focus:ring-blue-300'
                    }`}
                  >
                    Alterar Método de Pagamento
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        {paymentData.nextPaymentDate && !isEditing && (
          <p className="mt-4 text-sm text-gray-600">
            Próximo pagamento: {new Date(paymentData.nextPaymentDate).toLocaleDateString('pt-BR')}
          </p>
        )}
      </div>
    </div>
  );
};

export default PaymentUser;