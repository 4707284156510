import React, { Fragment, useEffect, useRef, useState } from 'react'
import Cookies from 'universal-cookie'
import ChatProApi from '../../../services/ChatProApi';
import { Button, Dialog, DialogTrigger, Heading, Modal } from 'react-aria-components';
import { MultiSelectComboBox } from '@hilla/react-components/MultiSelectComboBox';
import { ChevronLeftIcon, TrashIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import ChatConfigApi from '../../../services/ChatConfigApi';
import UserApi from '../../../services/UserApi';
import AuthService from '../../../services/Auth';
import { IconLogout, IconPhone, IconPhoneCall, IconPlugConnected, IconPlugConnectedX, IconUserCode, IconUserDown, IconUserEdit, IconUserPlus } from '@tabler/icons-react';
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import Pako from 'pako';
import { toast } from 'react-toastify';
import { Icon } from 'leaflet';
import { a } from 'framer-motion/dist/types.d-6pKw1mTI';
import clsx from 'clsx';

export default function Departamentos() {

    const cookies = new Cookies();
    const [profiles, setProfiles] = useState<any[]>([]);
    const [isMobile, setIsMobile] = useState(false);
    const [selectedItems, setSelectedItems] = useState({} as any);
    const [pais, setPais] = React.useState('Brasil');
    const [isInnerDialogOpen, setIsInnerDialogOpen] = useState(false);
    const [isInnerDialogOpenDepartamento, setIsInnerDialogOpenDepartamento] = useState(false);
    const [nome, setNome] = useState("");
    const [telefone, setTelefone] = useState("");
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [email, setEmail] = useState("");
    const [dataNascimento, setDataNascimento] = useState('');
    const [departamentoSelected, setDepartamentoSelected] = useState({} as any);
    const [isLoading, setIsLoading] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);
    const [nomeDepartamento, setNomeDepartamento] = useState("");
    const [dataHoraInicio, setDataHoraInicio] = useState("");
    const [dataHoraFim, setDataHoraFim] = useState("");
    const [tipoUsuario, setTipoUsuario] = useState("");
    const [departamentos, setDepartamentos] = useState({} as any);
    const [selectedDepartments, setSelectedDepartments] = useState<{ [key: string]: any[] }>({});

    useEffect(() => {

        ChatConfigApi.listCategoriaAtendente({
            "filters": {
                "codUserInstance": cookies.get('instancias')[0].id
            }
        }).then((response) => {
            console.log(response);
            setDepartamentos(response);
        });
        fetchProfiles();
    }, []);


    const handleSelectionChange = (rId: string) => (event: any) => {
        const selectedItems = event.target.selectedItems;
        console.log(selectedItems);
        setSelectedDepartments((prevState) => ({
            ...prevState,
            [rId]: selectedItems
        }));
    };

    const handleRemoveDepartament = (data: any) => {

        ChatConfigApi.deleteCategoriaAtendente(data.id).then((r: any) => {
            console.log(r);
            cookies.set('user', JSON.stringify(r.original.user), { path: '/', expires: moment().add(1, 'day').toDate() });
            cookies.set('instancias', JSON.stringify(r.original.instances), { path: '/', expires: moment().add(1, 'day').toDate() });
            cookies.set('atendentes', JSON.stringify(r.original.atendentes), { path: '/', expires: moment().add(1, 'day').toDate() });
            ChatConfigApi.listCategoriaAtendente({
                "filters": {
                    "codUserInstance": cookies.get('instancias')[0].id
                }
            }).then((response) => {
                setDepartamentos(response);
            });
        })
    }

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        loop: true,
        slides: { perView: 1, spacing: 10 },
    });

    const [sliderDeskRef, instanceDeskRef] = useKeenSlider<HTMLDivElement>({
        loop: true,
        slides: { perView: 2, spacing: 10 },
    });

    // Define o foco no primeiro campo quando o modal abre
    useEffect(() => {
        if (isInnerDialogOpen && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isInnerDialogOpen]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 1024);
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleAddDepartamento = () => {

        ChatConfigApi.createCategoriaAtendente({
            'setor': nomeDepartamento,
            'horaInicio': dataHoraInicio,
            'horaFim': dataHoraFim,
            "codUserInstance": cookies.get('instancias')[0].id
        }).then((r) => {
            console.log(r)
            cookies.set('user', JSON.stringify(r.original.user), { path: '/', expires: moment().add(1, 'day').toDate() });
            cookies.set('instancias', JSON.stringify(r.original.instances), { path: '/', expires: moment().add(1, 'day').toDate() });
            cookies.set('atendentes', JSON.stringify(r.original.atendentes), { path: '/', expires: moment().add(1, 'day').toDate() });

            ChatConfigApi.listCategoriaAtendente({
                "filters": {
                    "codUserInstance": cookies.get('instancias')[0].id
                }
            }).then((response) => {
                setDepartamentos(response);
            });
        });
    }

    const handleUpdateDepartamento = () => {

        const updateCategoriaAtendente = {
            'setor': nomeDepartamento,
            'horaInicio': dataHoraInicio,
            'horaFim': dataHoraFim,
        }

        ChatConfigApi.updateCategoriaAtendente(
            departamentoSelected['id'], updateCategoriaAtendente
        ).then((r: any) => {
            console.log(r)
            setDepartamentoSelected({});
            setNomeDepartamento('');
            setDataHoraFim('');
            setDataHoraInicio('');
            cookies.set('user', JSON.stringify(r.original.user), { path: '/', expires: moment().add(1, 'day').toDate() });
            cookies.set('instancias', JSON.stringify(r.original.instances), { path: '/', expires: moment().add(1, 'day').toDate() });
            cookies.set('atendentes', JSON.stringify(r.original.atendentes), { path: '/', expires: moment().add(1, 'day').toDate() });
            ChatConfigApi.listCategoriaAtendente({
                "filters": {
                    "codUserInstance": cookies.get('instancias')[0].id
                }
            }).then((response) => {
                setDepartamentos(response);
            });
        });
    }

    const handleDeleteAtendente = (id: any) => {
        AuthService.deleteAtendente(id).then((r: any) => {
            UserApi.showUsersAtendentesAll().then((atendentes: any) => {
                cookies.set('atendentes', atendentes.data.AllUsers, {
                    path: '/',
                    expires: moment().add(1, 'day').toDate(),
                });

                fetchProfiles();
                setNome('');
                setTelefone('');
                setEmail('');
                setCpfCnpj('');
                setTipoUsuario('');
                setIsInnerDialogOpen(false); // Fecha o modal após o sucesso
            });
        })

    }

    const register = () => {

        var block = false;
        var cpf = '';
        if (!nome.trim()) {
            toast.error("O nome é obrigatório.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            block = true;
        }

        if (!telefone.trim()) {
            toast.error("O telefone é obrigatório.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            block = true;
        }

        if (!email.trim()) {
            toast.error("O e-mail é obrigatório.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            block = true;
        }

        if (!cpfCnpj.trim()) {
            toast.error("O CPF/CNPJ é obrigatório.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            block = true;
        }
        else {
            cpf = cpfCnpj;
        }

        if (!tipoUsuario.trim()) {
            toast.error("Selecione o tipo de usuário.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
            });
            block = true;
        }

        if (block)
            return;

        AuthService.registerAtendente(nome, dataNascimento, telefone, email, pais, cpf, '', tipoUsuario)
            .then((response: any) => {
                if (response.error === "Erro ao enviar mensagem via WhatsApp: Número do WhatsApp não encontrado!" || response.error === "Erro ao enviar mensagem via WhatsApp: Número do WhatsApp não encontrado.") {
                    toast.error("WhatsApp não encontrado!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                    });
                    return;
                }
                toast.success("Usuário foi criado.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                UserApi.showUsersAtendentesAll().then((atendentes: any) => {
                    cookies.set('atendentes', atendentes.data.AllUsers, {
                        path: '/',
                        expires: moment().add(1, 'day').toDate(),
                    });

                    fetchProfiles();
                    setNome('');
                    setTelefone('');
                    setEmail('');
                    setCpfCnpj('');
                    setTipoUsuario('');
                    setIsInnerDialogOpen(false); // Fecha o modal após o sucesso
                });
            })
            .catch((error: any) => {
                console.error("Erro ao criar usuário:", error);
            });
    };


    const fetchProfiles = async () => {
        try {
            const compressedData = Uint8Array.from(atob(cookies.get('atendentes')), c => c.charCodeAt(0));

            // Descomprime os dados
            const jsonString = new TextDecoder().decode(Pako.inflate(compressedData));

            const atendentes = JSON.parse(jsonString) || [];
            setIsLoading(true);
            console.log(atendentes);
            const profileData = await Promise.all(
                atendentes.map(async (a: any) => {
                    try {
                        const response = await ChatProApi.getProfile({ number: "55" + a.telefone });

                        const departamentosAtendente = a.instances_cliente?.flatMap((instance: any) => instance.atendente_alocados) || [];
                        const departamentosSelecionados = cookies.get('instancias')[0].categoria_atendente.filter((departamento: any) =>
                            departamentosAtendente.some((alocado: any) =>
                                alocado.codAtendente === a.id && alocado.idCategoria === departamento.id
                            )
                        );

                        if (selectedDepartments[a.id] === undefined) {
                            setSelectedDepartments((prevState) => ({
                                ...prevState,
                                [a.id]: departamentosSelecionados,
                            }));
                        }

                        const img = response?.data?.response?.imgUrl || "./Anoni.jpg";
                        return { ...a, img, departamentosSelecionados };
                    } catch (error) {
                        console.error("Erro ao buscar perfil:", error);
                        return { ...a, img: "./Anoni.jpg" }; // Retorna com imagem padrão se houver erro
                    }
                })
            );

            console.log(profileData);
            setProfiles(profileData);
            setIsLoading(false);
        } catch (error) {
            console.error("Erro ao buscar perfis:", error);
            setIsLoading(false);
        }
    };

    const alocarAtendentes = (id: number, selectedDepartments: any) => {
        const atendenteId = id;
        const departamentosAlocados = selectedDepartments;

        const instanciasJaAlocadas = cookies.get('instancias')[0].atendente_alocados.filter((i: any) => i.codAtendente === atendenteId);

        const novosDepartamentos = departamentosAlocados.filter((departamento: any) => {
            return !instanciasJaAlocadas.some((i: any) => i.idCategoria === departamento.id);
        });

        const dadosParaAdicionar = {
            atendenteId: atendenteId,
            departamentos: novosDepartamentos,
        };

        console.log(dadosParaAdicionar);

        const departamentosParaRemover = instanciasJaAlocadas.filter((i: any) => {
            return !departamentosAlocados.some((dep: any) => dep.id === i.idCategoria);
        });

        const dadosParaRemover = {

            departamentos: departamentosParaRemover.map((departamento: any) => ({
                id: departamento.id
            }))
        };

        console.log(dadosParaAdicionar, dadosParaRemover)
        if (novosDepartamentos.length > 0) {
            novosDepartamentos.map((d: any) => {
                ChatConfigApi.createAtendenteAlocado({ 'codAtendente': atendenteId, 'codUserInstance': d.codUserInstance, 'idCategoria': d.id }).then((r) => {
                    cookies.set('user', JSON.stringify(r.original.user), { path: '/', expires: moment().add(1, 'day').toDate() });
                    cookies.set('instancias', JSON.stringify(r.original.instances), { path: '/', expires: moment().add(1, 'day').toDate() });
                    cookies.set('atendentes', JSON.stringify(r.original.atendentes), { path: '/', expires: moment().add(1, 'day').toDate() });

                    fetchProfiles();
                });

            })
        }

        if (dadosParaRemover.departamentos.length > 0) {
            dadosParaRemover.departamentos.forEach((departamento: any) => {
                ChatConfigApi.removeDepartamentoAlocado(departamento.id).then((r) => {
                    cookies.set('user', JSON.stringify(r.original.user), { path: '/', expires: moment().add(1, 'day').toDate() });
                    cookies.set('instancias', JSON.stringify(r.original.instances), { path: '/', expires: moment().add(1, 'day').toDate() });
                    cookies.set('atendentes', JSON.stringify(r.original.atendentes), { path: '/', expires: moment().add(1, 'day').toDate() });

                    fetchProfiles();
                });
            })
        }

    };

    return (
        <section className="flex w-full h-full bg-white">
            <div className="flex-col items-center py-2 px-1 w-full h-auto md:inline-flex lg:inline-flex xl:inline-flex">
                <section className="bg-[#f3f4f6] m-2 dark:bg-gray-900 rounded-md">
                    <h1 className="text-2xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center">
                        Colaboradores e Departamentos
                    </h1>
                    <div className="container px-4 py-5 mx-auto">

                        <div className="px-0 sm:px-6 lg:px-8">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <p className="mt-2 text-xs text-center text-gray-700">
                                        Uma lista de todos os usuários da sua conta, incluindo nome, cargo, e-mail e função.
                                    </p>
                                </div>
                                <div className="mt-4 py-2 sm:ml-16 sm:mt-0 sm:flex-none relative">
                                    {profiles.length > 0 &&
                                        <>
                                            <button
                                                type="button"
                                                onClick={() => setIsInnerDialogOpen(true)}
                                                className="block rounded-md bg-green-600 px-2 py-1 mt-2 text-center text-xs font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                            >
                                                Adicionar Colaborador
                                            </button>
                                        </>
                                    }
                                    {/* Modal Simples Dentro do DialogTriager */}
                                    {isInnerDialogOpen && (
                                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm">
                                            <div className="relative shadow-lg bg-white rounded-lg w-96">

                                                {/* Cabeçalho */}
                                                <div className="sticky top-0 bg-gray-100 p-1 flex items-center justify-between rounded-lg">
                                                    <button
                                                        className="flex items-center w-full text-gray-400 hover:text-gray-600 rounded-lg"
                                                        onClick={() => setIsInnerDialogOpen(false)}
                                                    >
                                                        <ChevronLeftIcon className="h-6 w-6" />
                                                        <span className="ml-2">Voltar</span>
                                                    </button>
                                                </div>
                                                <div className='p-2'>

                                                    {/* Título */}
                                                    <h1 className="text-xl font-bold text-gray-500 text-center my-3">
                                                        Crie uma conta de acesso para um Colaborador
                                                    </h1>

                                                    {/* Formulário */}
                                                    <div className="space-y-3">

                                                        {/* Nome */}
                                                        <input
                                                            type="text"
                                                            className="w-full border border-gray-300 rounded p-2 text-gray-700"
                                                            placeholder="Nome"
                                                            value={nome}
                                                            onChange={(e) => setNome(e.target.value)}
                                                        />

                                                        {/* Telefone */}
                                                        <input
                                                            type="text"
                                                            className="w-full border border-gray-300 rounded p-2 text-gray-700"
                                                            placeholder="Telefone"
                                                            value={telefone}
                                                            onChange={(e) => setTelefone(e.target.value)}
                                                        />

                                                        {/* Email */}
                                                        <input
                                                            type="email"
                                                            className="w-full border border-gray-300 rounded p-2 text-gray-700"
                                                            placeholder="E-mail"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />

                                                        {/* CPF/CNPJ */}
                                                        <input
                                                            type="text"
                                                            className="w-full border border-gray-300 rounded p-2 text-gray-700"
                                                            placeholder="CPF"
                                                            value={cpfCnpj}
                                                            onChange={(e) => setCpfCnpj(e.target.value)}
                                                        />

                                                        {/* Tipo de Usuário */}
                                                        <select
                                                            className="w-full border border-gray-300 rounded p-2 text-gray-700"
                                                            value={tipoUsuario}
                                                            onChange={(e) => setTipoUsuario(e.target.value)}
                                                        >
                                                            <option value="">Tipo de Usuário</option>
                                                            <option value="Coordenador">Coordenador</option>
                                                            <option value="Atendente">Atendente</option>
                                                        </select>

                                                        {/* Mensagem */}
                                                        <small className="text-xs text-center font-light block">
                                                            A senha do colaborador será enviada por mensagem no WhatsApp.
                                                        </small>

                                                        {/* Botão de Adicionar */}
                                                        <div className="flex justify-center">
                                                            <button
                                                                className="bg-green-500 hover:bg-green-400 text-white font-semibold px-4 py-2 rounded shadow"
                                                                onClick={() => register()}
                                                            >
                                                                Adicionar Colaborador
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {!isLoading && profiles.length === 0 &&
                                <>
                                    <div className='flex items-center justify-center text-center'>
                                        <button
                                            type="button"
                                            onClick={() => setIsInnerDialogOpen(true)}
                                            className="relative mt-2 flex flex-col items-center justify-center w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                        >
                                            <IconUserPlus className='w-10 h-10' />
                                            <span className="mt-2 block text-sm font-semibold text-gray-900">Adicionar Colaborador</span>
                                        </button>
                                    </div>

                                </>
                            }
                            <div className="mt-1">
                                {isLoading ? (
                                    <div className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-2">
                                        {[...Array(isMobile ? 1 : 2)].map((_, index) => (
                                            <div
                                                key={index}
                                                className="flex flex-col items-center bg-gray-50 p-8 m-2 md:m-10 transition-colors duration-300 transform cursor-pointer group rounded-xl animate-pulse"
                                            >
                                                <div className="w-24 h-24 bg-gray-300 rounded-full ring-4 ring-gray-300"></div>
                                                <div className="w-32 h-4 mt-4 bg-gray-200 rounded-lg"></div>
                                                <div className="w-20 h-4 mt-2 bg-gray-200 rounded-lg"></div>
                                                <div className="flex">
                                                    <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
                                                    <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
                                                </div>
                                                <div className="w-20 h-8 mt-4 bg-gray-300 rounded-lg"></div>
                                            </div>
                                        ))}
                                    </div>
                                ) : isMobile ? (
                                    <div className="relative w-full max-w-xl mx-auto">
                                        <div ref={sliderRef} className="keen-slider">
                                            {profiles.map((a) => (
                                                <div key={a.id} className="keen-slider__slide flex flex-col items-center bg-gray-50 p-8 rounded-xl" >
                                                    <div className="relative w-24 h-24">
                                                        <img
                                                            className="object-cover w-24 h-24 rounded-full ring-4 ring-gray-300"
                                                            src={a.img}
                                                            alt={a.nome}
                                                        />
                                                        {/* Indicador de status */}
                                                        <span
                                                            className={`absolute bottom-1 right-1 w-5 h-5 border-2 border-white rounded-full ${a.online ? "bg-green-500" : "bg-red-400"
                                                                }`}
                                                        />
                                                    </div>
                                                    <h1 className="mt-4 text-2xl text-center font-semibold text-gray-700 capitalize dark:text-white">
                                                        {a.nome}
                                                    </h1>
                                                    <p className="mt-1 text-gray-500 capitalize dark:text-gray-300">
                                                        {a.is_coordenador === true ? "Coordenador" : "Atendente"}
                                                    </p>
                                                    <div className="flex">
                                                        {cookies.get('instancias').map((r: any) => {
                                                            const todosDepartamentos = r.categoria_atendente || [];

                                                            return (
                                                                <MultiSelectComboBox
                                                                    key={r.id}
                                                                    className="my-custom-multi-select bg-gray-50 p-2 w-full"
                                                                    label={`Departamentos ${r.info.response.info.Pushname}`}
                                                                    itemLabelPath="setor"
                                                                    itemIdPath="id"
                                                                    items={todosDepartamentos}
                                                                    onChange={handleSelectionChange(a.id)}
                                                                    selectedItems={selectedDepartments[a.id]}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                    <div className='flex row mt-10'>
                                                        <button
                                                            className="rounded-lg bg-green-500 text-center text-small font-semibold text-white shadow-md outline-none ring-green-500 hover:bg-green-400 focus:ring-2 w-auto h-10 mt-2 p-2"
                                                            onClick={() => alocarAtendentes(a.id, a.departamentosSelecionados)}
                                                        >
                                                            <p className='flex'><IconUserEdit className='w-6 h-6' /> Salvar</p>
                                                        </button>
                                                        <button onClick={() => { handleDeleteAtendente(a.id) }}
                                                            className="rounded-lg bg-red-500 text-center text-small font-semibold text-white shadow-md outline-none ring-red-500 hover:bg-red-400 focus:ring-2 w-auto h-10 mt-2 ml-2 p-2"
                                                        >
                                                            <p className='flex'><IconUserDown className='w-6 h-6' /> Desvincular</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {profiles.length > 1 && (<>
                                            <button
                                                className="absolute left-0 top-1/2 -translate-y-1/2 p-2 bg-gray-200 rounded-full shadow-md hover:bg-gray-300"
                                                onClick={() => instanceRef.current?.prev()}
                                            >
                                                ◀
                                            </button>
                                            <button
                                                className="absolute right-0 top-1/2 -translate-y-1/2 p-2 bg-gray-200 rounded-full shadow-md hover:bg-gray-300"
                                                onClick={() => instanceRef.current?.next()}
                                            >
                                                ▶
                                            </button> </>)}
                                    </div>
                                ) : (
                                    <div className="relative">
                                        <div ref={sliderDeskRef} className="keen-slider">
                                            {profiles.map((a: any, index: number) => (
                                                <div key={index} className="keen-slider__slide keen-slider__slide_desk flex flex-col items-center ml-2 bg-gray-50 p-6 rounded-xl" >
                                                    <div className="relative w-24 h-24">
                                                        <img
                                                            className="object-cover w-24 h-24 rounded-full ring-4 ring-gray-300"
                                                            src={a.img || ".Anoni.jpg"}
                                                            alt={a.nome}
                                                        />
                                                        {/* Indicador de status */}
                                                        <span
                                                            className={`absolute bottom-1 right-1 w-5 h-5 border-2 border-white rounded-full ${a.online ? "bg-green-500" : "bg-red-400"
                                                                }`}
                                                        />
                                                    </div>
                                                    <h1 className="mt-4 text-2xl text-center font-semibold text-gray-700 capitalize dark:text-white">
                                                        {a.nome}
                                                    </h1>
                                                    <p className="mt-1 text-gray-500 capitalize dark:text-gray-300">{a.is_coordenador === true ? "Coordenador" : "Atendente"}</p>
                                                    <div className="flex">
                                                        {cookies.get('instancias').map((r: any) => {
                                                            const todosDepartamentos = r.categoria_atendente || [];

                                                            return (
                                                                <MultiSelectComboBox
                                                                    key={r.id}
                                                                    className="my-custom-multi-select bg-gray-50 p-2 w-full"
                                                                    label={`Departamentos ${r.info.response.info.Pushname}`}
                                                                    itemLabelPath="setor"
                                                                    itemIdPath="id"
                                                                    items={todosDepartamentos}
                                                                    onChange={handleSelectionChange(a.id)}
                                                                    selectedItems={selectedDepartments[a.id]}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                    <div className='row mt-10'>
                                                        <button
                                                            className="rounded-lg bg-green-500 text-center text-base font-semibold text-white shadow-md outline-none ring-green-500 hover:bg-green-400 focus:ring-2 w-50 h-10 mt-2 ml-2 p-2"
                                                            onClick={() => alocarAtendentes(a.id, selectedDepartments[a.id])}
                                                        >
                                                            <p className='flex'><IconUserEdit className='w-6 h-6' /> Salvar</p>
                                                        </button>
                                                        <button onClick={() => { handleDeleteAtendente(a.id) }}
                                                            className="rounded-lg bg-red-500 text-center text-base font-semibold text-white shadow-md outline-none ring-red-500 hover:bg-red-400 focus:ring-2 w-50 h-10 mt-2 ml-2 p-2"
                                                        >
                                                            <p className='flex'><IconUserDown className='w-6 h-6' /> Desvincular</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {profiles.length > 1 && (<>
                                            <button
                                                className="absolute left-0 top-1/2 -translate-y-1/2 p-2 bg-gray-200 rounded-full shadow-md hover:bg-gray-300"
                                                onClick={() => instanceDeskRef.current?.prev()}
                                            >
                                                ◀
                                            </button>
                                            <button
                                                className="absolute right-0 top-1/2 -translate-y-1/2 p-2 bg-gray-200 rounded-full shadow-md hover:bg-gray-300"
                                                onClick={() => instanceDeskRef.current?.next()}
                                            >
                                                ▶
                                            </button> </>)}

                                    </div>
                                )}
                            </div>


                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <p className="mt-2 text-xs text-center text-gray-700">
                                        Uma lista de todos os departamentos, incluindo seus atendentes alocados.
                                    </p>
                                </div>

                                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none relative">
                                    {/* Botão para abrir o modal interno */}
                                    <button
                                        type="button"
                                        onClick={() => {setIsInnerDialogOpenDepartamento(true);
                                            ChatConfigApi.listCategoriaAtendente({
                                                "filters": {
                                                    "codUserInstance": cookies.get('instancias')[0].id
                                                }
                                            }).then((response) => {
                                                console.log(response);
                                                setDepartamentos(response.data);
                                            });
                                            fetchProfiles();
                                        }}
                                        className="block rounded-md bg-green-600 px-2 py-1 mt-2 text-center text-xs font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                        Gerenciar Departamentos
                                    </button>

                                    {/* Modal Simples Dentro do DialogTriager */}
                                    {isInnerDialogOpenDepartamento && (
                                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm overflow-y-auto">
                                            <div className="relative shadow-lg bg-white rounded-lg w-96">

                                                {/* Cabeçalho */}
                                                <div className="sticky top-0 bg-gray-100 p-2 flex items-center justify-between rounded-lg">
                                                    <button
                                                        className="flex items-center w-full text-gray-400 hover:text-gray-600 rounded-lg"
                                                        onClick={() => setIsInnerDialogOpenDepartamento(false)}
                                                    >
                                                        <ChevronLeftIcon className="h-6 w-6" />
                                                        <span className="ml-2">Voltar</span>
                                                    </button>
                                                </div>
                                                <div className='p-4'>

                                                    {/* Título */}
                                                    <h1 className="text-xl font-bold text-gray-500 text-center my-3">
                                                        Departamentos de atendimento.
                                                    </h1>

                                                    {/* Formulário */}
                                                    <div className="space-y-3">

                                                        <div className="w-full mt-2 block">

                                                            <label htmlFor="datetime-start" className="font-normal text-sm mt-2 ml-2 text-gray-700 whitespace-nowrap">
                                                                Adicionar/Editar Departamento
                                                            </label>
                                                            <div className='m-auto p-2 col-3 bg-white rounded-lg w-full '>
                                                                <div className="flex flex-row mr-1">

                                                                    <select
                                                                        id="countries_disabled"
                                                                        value={departamentoSelected.id !== undefined ? departamentoSelected.id : ''}
                                                                        onChange={
                                                                            (e) => {
                                                                                var key = parseInt(e.target.value);
                                                                                console.log(key);
                                                                                var departamentoSelecionado = departamentos.find((d: any) => d.id === key);
                                                                                console.log(departamentoSelecionado);
                                                                                if (departamentoSelecionado !== undefined) {
                                                                                    setDepartamentoSelected(departamentoSelecionado);
                                                                                    if (departamentoSelecionado['setor'] !== undefined) {
                                                                                        setNomeDepartamento(departamentoSelecionado['setor'])
                                                                                    }
                                                                                    if (departamentoSelecionado['horaInicio'] !== undefined) {
                                                                                        setDataHoraInicio(departamentoSelecionado['horaInicio'].slice(0, -3))
                                                                                    }
                                                                                    if (departamentoSelecionado['horaFim'] !== undefined) {
                                                                                        setDataHoraFim(departamentoSelecionado['horaFim'].slice(0, -3))
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    setDepartamentoSelected({});
                                                                                    setNomeDepartamento('');
                                                                                    setDataHoraInicio('');
                                                                                    setDataHoraFim('');
                                                                                }
                                                                            }}
                                                                        className="bg-gray-50 border mr-4 border-green-300 rounded-lg py-2 px-4 text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent w-full"
                                                                    >
                                                                        <option className='text-gray-400' value="">Adicionar Departamento</option>
                                                                        {departamentos.length > 0 && departamentos.map((r: any) => {
                                                                            return (
                                                                                <>
                                                                                    <option value={r.id}>{r.setor} ({r.horaInicio.slice(0, -3)} - {r.horaFim.slice(0, -3)})</option>
                                                                                </>)
                                                                        }
                                                                        )}
                                                                    </select>
                                                                    {departamentoSelected.id !== undefined &&
                                                                        <button
                                                                            type="submit" onClick={() => { handleRemoveDepartament(departamentoSelected) }}
                                                                            className="px-2 h-23 w-22 hover:bg-red-400 focus:ring-red-500 focus:ring-offset-red-200 text-dark transition ease-in duration-200 text-center text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                                                                        >
                                                                            <TrashIcon className='w-6 h-6' />
                                                                        </button>
                                                                    }
                                                                </div>
                                                                <div className="max-w-lg mx-auto">

                                                                    <label htmlFor="datetime-start" className="font-normal text-sm mt-2 text-gray-700 whitespace-nowrap">
                                                                        Nome
                                                                    </label>
                                                                    <div className="relative ">

                                                                        <input
                                                                            type="text"
                                                                            id="Title"
                                                                            value={nomeDepartamento}
                                                                            className="rounded-md flex w-full border border-green-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
                                                                            onChange={(e) => setNomeDepartamento(e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="flex space-x-2 ml-4">
                                                                        <div className="flex flex-col mr-1">
                                                                            <label htmlFor="datetime-start" className="font-normal text-sm mt-2 ml-4 text-gray-700 whitespace-nowrap">
                                                                                Horário Inicio
                                                                            </label>
                                                                            <input
                                                                                id="datetime-start"
                                                                                type="time"
                                                                                value={dataHoraInicio}
                                                                                className="bg-gray-50 border text-sm text-center  border-green-300 rounded-lg xsm:ml-4 py-2 xsm:px-1 sm:px-4 md:px-4 lg:px-4 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent w-[122px]"
                                                                                onChange={(e) => setDataHoraInicio(e.target.value)}
                                                                                placeholder="Select date and time"
                                                                            />
                                                                        </div>

                                                                        <div className="flex flex-col">
                                                                            <label htmlFor="datetime-finish" className="font-normal text-sm mt-2 ml-4 text-gray-700 whitespace-nowrap">
                                                                                Horário Fim
                                                                            </label>
                                                                            <input
                                                                                id="datetime-finish"
                                                                                type="time"
                                                                                value={dataHoraFim}
                                                                                onChange={(e) => setDataHoraFim(e.target.value)}
                                                                                className="bg-gray-50 border text-center text-sm border-green-300 rounded-lg xsm:ml-4 py-2 xsm:px-1 sm:px-4 md:px-4 lg:px-4 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent w-[122px]"
                                                                                placeholder="Select date and time"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        type="submit"
                                                                        className="py-2 px-4 mt-2 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                                                        onClick={() => { departamentoSelected['setor'] !== undefined ? handleUpdateDepartamento() : handleAddDepartamento() }}
                                                                    >
                                                                        Departamento {departamentoSelected['setor'] !== undefined ? 'Update' : 'Insert'}
                                                                    </button>


                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>

                        <section className="container px-4 mx-auto">
                            <div className="flex flex-col mt-6">
                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                                <thead className="bg-gray-50 dark:bg-gray-800">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                        >
                                                            Departamento
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                        >
                                                            Hora Início
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                        >
                                                            Hora Fim
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                        >
                                                            Colaboradores
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                                        >
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                                    {cookies.get("instancias")[0].categoria_atendente.map((c: any) => { return (
                                                            <tr>
                                                                <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                                                                    <div>
                                                                        <h2 className="font-medium text-gray-800 dark:text-white ">
                                                                            {c.setor}
                                                                        </h2>
                                                                    </div>
                                                                </td>
                                                                <td className="px-12 py-2 text-sm font-medium whitespace-nowrap">
                                                                    <div className="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                                                                        {c.horaInicio}
                                                                    </div>
                                                                </td>
                                                                <td className="px-4 py-2 text-sm whitespace-nowrap">
                                                                    <div className="inline px-3 py-1 text-sm font-normal rounded-full text-red-500 gap-x-2 bg-red-100/60 dark:bg-gray-800">
                                                                        {c.horaFim}
                                                                    </div>
                                                                </td>
                                                                <td className="px-4 py-2 text-sm whitespace-nowrap">
                                                                    <div className="flex items-center">
                                                                        {profiles
                                                                            .filter((profile: any) =>
                                                                                profile.departamentosSelecionados.some((departamento: any) => departamento.id === c.id)
                                                                            )
                                                                            .map((profile: any) => (
                                                                                <img
                                                                                    key={profile.id} // Garante que cada imagem tenha uma chave única
                                                                                    className="object-cover w-6 h-6 -mx-1 border-2 border-white rounded-full dark:border-gray-700 shrink-0"
                                                                                    src={profile.img ?? ".Anoni.png"}
                                                                                    alt={profile.nome ?? "Colaborador"}
                                                                                />
                                                                            ))}
                                                                    </div >
                                                                </td>
                                                                <td className="px-4 py-2 text-sm whitespace-nowrap">
                                                                    {/*  <button className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg dark:text-gray-300 hover:bg-gray-100">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="1.5"
                                                                            stroke="currentColor"
                                                                            className="w-6 h-6"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                                                            />
                                                                        </svg>
                                                                    </button> */}
                                                                </td>
                                                            </tr>
                                                        )})}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </section>

            </div >

        </section >

    )
}
