import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useQuery, useQueryClient, QueryClient, QueryClientProvider } from 'react-query';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Cookies from 'universal-cookie';
import Pako from 'pako';
import { FixedSizeList as List } from 'react-window';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import { Button, Dialog, DialogTrigger, Heading, Input, Label, Modal, TextField } from 'react-aria-components';
import {
  BanknotesIcon, BoltIcon, ChatBubbleLeftRightIcon, ChevronLeftIcon,
  FunnelIcon, HomeIcon, LinkIcon, ShoppingCartIcon, XCircleIcon
} from '@heroicons/react/24/solid';
import { ChatBubbleBottomCenterTextIcon, CheckCircleIcon, RocketLaunchIcon } from '@heroicons/react/24/outline';

// Services
import chatProApi from '../../../services/ChatProApi';
import chatConfigApi from '../../../services/ChatConfigApi';
import UserApi from "../../../services/UserApi";
import AuthService from "../../../services/Auth";
import StoreApi from '../../../services/StoreApi';

// Components
import EmojiPicker from '../../../components/Utils/EmojiPicker';
import BotConfig from './BotConfig';
import ChatComponent from './ChatComponent';
import ChatProApi from '../../../services/ChatProApi';
import { IconBalloonOff, IconBrandOffice, IconClockQuestion, IconDeviceDesktopCheck, IconDeviceDesktopSearch, IconMessage2X, IconPhoneCall, IconReportMoney, IconUserQuestion, IconUsersGroup, IconUsersPlus, IconUserSquare } from '@tabler/icons-react';

// Types
interface Profile {
  id: string;
  nome: string;
  telefone: string;
  celular?: string;
  img: string;
  online?: boolean;
  imgUrl?: string;
  pushname?: string;
  unread?: number;
  lastMessageTime?: number;
  jid?: string;
  name?: string;
}

interface ProfilesData {
  atendentes: Profile[];
  clientes: Profile[];
}

interface HomeState {
  infoInstance: any[];
  profiles: ProfilesData;
  atendentes: any[];
  clientes: any[];
  filaEspera: any[];
  mensagens: {
    response: string;
    responseOriginal: string;
    inicio: string;
    fim: string;
  };
  pedidos: {
    data: any[];
    currentPage: number;
    totalPages: number;
    loading: boolean;
    filterStatus: string;
    filterDate: string;
  };
  contatos: any[];
  perfisClientes: Record<string, any>;
  showChat: boolean;
  selectedContact: string | null;
  selectedContactImage: string;
  selectedContactName: string;
  conversas: any;
  imgPerfil: string;
  motivoCancelamento: string;
  editing: 'response' | 'inicio' | 'fim';
  initialLoad: boolean;
  cachedDataLoaded: boolean;
}

const LOCAL_STORAGE_KEYS = {
  atendentes: 'cache_atendentes',
  clientes: 'cache_clientes',
  contatos: 'cache_contatos',
  pedidos: 'cache_pedidos',
  filaEspera: 'cache_filaEspera'
};

// Função para comprimir e codificar com Base64
const compressAndEncode = (value: any) => {
  try {
    const jsonString = JSON.stringify(value);
    const compressed = Pako.deflate(jsonString);
    const binaryString = compressed.reduce((data, byte) => data + String.fromCharCode(byte), '');
    return btoa(binaryString);
  } catch (error) {
    console.error('Erro ao compactar valor:', error);
    return null;
  }
};

const getStorage = (key: string) => {
  try {
    const raw = localStorage.getItem(key);
    if (raw) return JSON.parse(raw);
  } catch (err) {
    console.warn(`Erro ao ler cache de ${key}:`, err);
  }
  return null;
};

const setStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.warn(`Erro ao salvar cache de ${key}:`, err);
  }
}

const queryClient = new QueryClient();

const paginate = 4;
const cookies = new Cookies();
const userInstance = cookies.get('instancias');
const instancesCliente = cookies.get('user')?.instances_cliente;

// Componentes de Lista Otimizados
const ContactItem = React.memo(({ contact, onClick }: { contact: Profile, onClick: () => void }) => { 
  console.log(contact)
  return (
  <li className="py-1 sm:py-1 mr-4">
    <div className="flex items-center space-x-4">
      <div className="flex-shrink-0">
        <img
          className="h-12 w-12 rounded-full"
          src={contact?.img || "./Anoni.jpg"}
          alt="Avatar"
        />
      </div>
      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white cursor-pointer" onClick={onClick}>
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 13.4811 3.09753 14.8788 3.7148 16.1181C3.96254 16.6155 4.05794 17.2103 3.90163 17.7945L3.30602 20.0205C3.19663 20.4293 3.57066 20.8034 3.97949 20.694L6.20553 20.0984C6.78973 19.9421 7.38451 20.0375 7.88191 20.2852C9.12121 20.9025 10.5189 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.2817 22.75 8.65552 22.3463 7.21315 21.6279C6.99791 21.5207 6.77814 21.4979 6.59324 21.5474L4.3672 22.143C2.84337 22.5507 1.44927 21.1566 1.857 19.6328L2.4526 17.4068C2.50208 17.2219 2.47933 17.0021 2.37213 16.7869C1.65371 15.3445 1.25 13.7183 1.25 12ZM7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z" fill="#1C274C" />
        </svg>
      </div>
      <div className="flex-1 text-start min-w-0">
        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
          {contact?.name || contact?.nome}
        </p>
        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
          {contact?.unread ? `${contact.unread} mensagens não lidas` : contact?.celular || contact?.telefone}
        </p>
      </div>
    </div>
  </li>
)});

const PedidoItem = React.memo(({ pedido, perfisClientes, onAceitarPedido, onFinalizarPedido, onPedidoSaiuParaEntrega, onCancelarPedido, onSelectNumber }: {
  pedido: any,
  perfisClientes: Record<string, any>,
  onAceitarPedido: (pedido: any) => void,
  onFinalizarPedido: (pedido: any) => void,
  onPedidoSaiuParaEntrega: (pedido: any) => void,
  onCancelarPedido: (pedido: any) => void,
  onSelectNumber: (number: any) => void
}) => {
  const cliente = perfisClientes[pedido.telefoneCliente]?.data?.response;
  const [motivoCancelamento, setMotivoCancelamento] = useState("");

  const formatCurrency = useCallback((val: number, sign: string) => {
    const c = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: sign,
    }).format(val);

    const z = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: sign,
    }).format(0);

    return c !== z ? c : z;
  }, []);

  return (
    <>
      <tr key={pedido.id}>
        <td className="px-3 py-2 text-gray-900 font-bold">#{pedido.id}</td>
        <td className="w-auto px-3 py-2 flex items-center gap-2 align-middle">
          <div className='flex mt-2'>
            <img src={cliente?.imgUrl || "./Anoni.jpg"} alt="" className="w-10 h-10 rounded-full mt-2" />
            <small className="w-36 mt-2 block ml-2">
              <div className="text-gray-900">{cliente?.pushname}</div>
              <div className="text-gray-500">{pedido.telefoneCliente}</div>
            </small>
          </div>
        </td>
        <td className="w-96 px-3 py-2 text-gray-500 align-top">
          <small className="w-96 text-gray-700 mt-2 text-xs">
            <div className="text-sm whitespace-nowrap w-72">
              {pedido.enderecoCliente ? "Entrega" : "Retirada na Loja"}
            </div>
            {pedido.enderecoCliente ?? pedido.enderecoLoja}
          </small>
        </td>
        <td className="w-64 px-3 py-2 text-gray-500">
          {pedido.produtos?.map((produto: any) => (
            <div key={produto.nome}>
              <small className="whitespace-nowrap text-gray-700 mt-2 text-xs ">
                {produto.quantidade}x {produto.nome} - {formatCurrency(produto.preco * produto.quantidade, "BRL")}
              </small>
            </div>
          ))}
        </td>
        <td className="w-48 whitespace-nowrap px-3 py-2 text-gray-500">
          <small className="whitespace-nowrap text-gray-700 mt-2 text-xs text-center">
            {pedido.metodoPagamento === 'cartao' ? "Crédito/Débito" : pedido.metodoPagamento === 'dinheiro' ? "Dinheiro" : pedido.metodoPagamento}
          </small>
        </td>
        <td className="px-3 whitespace-nowrap py-2 text-gray-500">
          <span className='whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md'>
            {formatCurrency(pedido.valorProdutos, "BRL")}
          </span>
        </td>
        <td className="px-3 whitespace-nowrap py-2 text-gray-500">
          <span className='whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md'>
            {formatCurrency(pedido.valorFrete, "BRL")}
          </span>
        </td>
        <td className="px-3 whitespace-nowrap py-2 text-gray-500">
          <span className='whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md'>
            {formatCurrency(pedido.valorTotal, "BRL")}
          </span>
        </td>
        <td className="px-3 whitespace-nowrap py-2 text-gray-500">
          <span className='whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md'>
            {formatCurrency(pedido.troco, "BRL")}
          </span>
        </td>
        <td className="px-3 py-2 text-gray-500">
          <small className="block ml-2 text-center justify-center">
            <span className={`whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md ${pedido.status === "pendente" ? "text-yellow-700 bg-yellow-50" :
              pedido.status === "cancelado" ? "text-red-700 bg-red-50" :
                pedido.status === "entregue" ? "text-green-700 bg-green-50" :
                  pedido.status === "em_preparacao" ? "text-yellow-700 bg-yellow-50" :
                    pedido.status === "saiu_para_entrega" ? "text-yellow-700 bg-yellow-50" :
                      "text-red-700 bg-red-50"}`}>
              {pedido.status === "pendente" ? "Aguardando Confirmação" :
                pedido.status === "entregue" ? "Entregue" :
                  pedido.status === "em_preparacao" ? "Em Preparação" :
                    pedido.status === "saiu_para_entrega" ? "Saiu para Entrega" : "Cancelado"}
            </span>
            <div className="text-gray-500 mt-1 w-auto font-small text-xs">
              {pedido.status === "cancelado" && pedido.motivoCancelamento && pedido.motivoCancelamento}
            </div>
          </small>
        </td>
        <td className="whitespace-nowrap px-3 py-2 text-gray-500">{new Date(pedido.created_at).toLocaleString("pt-BR")}</td>
        <td className="w-36 px-3 py-2 text-right">
          <span className='whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md'>
            {pedido.status === "pendente" && (
              <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                <DialogTrigger>
                  <Button className="rounded-md transition">
                    <CheckCircleIcon className="w-6 h-6 text-green-300" />
                  </Button>
                  <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                    <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                      <form>
                        <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Aceitar Pedido</Heading>
                        <p className="text-gray-600 mt-4">Você tem certeza que deseja aceitar este pedido?</p>
                        <div className="mt-8 flex justify-end space-x-3">
                          <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                            Cancelar
                          </Button>
                          <Button
                            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                            onPress={() => onAceitarPedido(pedido)}
                          >
                            Confirmar
                          </Button>
                        </div>
                      </form>
                    </Dialog>
                  </Modal>
                </DialogTrigger>
              </div>
            )}

            {pedido.status === "saiu_para_entrega" && (
              <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                <DialogTrigger>
                  <Button className="rounded-md transition">
                    <BanknotesIcon className="w-6 h-6 text-green-300" />
                  </Button>
                  <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                    <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                      <form>
                        <Heading slot="title" className="leading-none mt-0 text-xl font-bold">O pedido foi entregue?</Heading>
                        <p className="text-gray-600 mt-4">Você tem certeza que deseja finalizar este pedido?</p>
                        <div className="mt-8 flex justify-end space-x-3">
                          <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                            Cancelar
                          </Button>
                          <Button
                            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                            onPress={() => onFinalizarPedido(pedido)}
                          >
                            Confirmar
                          </Button>
                        </div>
                      </form>
                    </Dialog>
                  </Modal>
                </DialogTrigger>
              </div>
            )}

            {pedido.status === "em_preparacao" && (
              <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                <DialogTrigger>
                  <Button className="rounded-md transition">
                    <RocketLaunchIcon className="w-6 h-6 text-gray-700" />
                  </Button>
                  <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                    <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                      <form>
                        <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Saindo Para Entrega?</Heading>
                        <p className="text-gray-600 mt-4">Você tem certeza que deseja alterar o status para "Saiu para Entrega" neste pedido?</p>
                        <div className="mt-8 flex justify-end space-x-3">
                          <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                            Cancelar
                          </Button>
                          <Button
                            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                            onPress={() => onPedidoSaiuParaEntrega(pedido)}
                          >
                            Confirmar
                          </Button>
                        </div>
                      </form>
                    </Dialog>
                  </Modal>
                </DialogTrigger>
              </div>
            )}

            <div
              className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer"
              onClick={() => onSelectNumber(cliente)}
            >
              <ChatBubbleBottomCenterTextIcon className="w-6 h-6 text-gray-500" />
            </div>

            {(pedido.status === "pendente" || pedido.status === "saiu_para_entrega") && (
              <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                <DialogTrigger>
                  <Button className="rounded-md transition">
                    <XCircleIcon className="w-6 h-6 text-red-300" />
                  </Button>
                  <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm">
                    <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                      <form>
                        <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Cancelar Pedido</Heading>
                        <p className="text-gray-600 mt-4">Tem certeza que deseja cancelar este pedido? Essa ação não pode ser desfeita.</p>
                        <div>
                          <label htmlFor="marca" className="block text-md font-semibold text-gray-700 mb-1">
                            Motivo Cancelamento:
                          </label>
                          <input
                            id="marca"
                            type="text"
                            name="marca"
                            value={motivoCancelamento}
                            onChange={(e) => setMotivoCancelamento(e.target.value)}
                            className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                          />
                        </div>
                        <div className="mt-8 flex justify-end space-x-3">
                          <Button className="px-4 py-2 bg-gray-300 text-red-700 rounded-md hover:bg-gray-400 transition" slot="close">
                            Cancelar
                          </Button>
                          <Button
                            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
                            onPress={() => {
                              if (motivoCancelamento) {
                                onCancelarPedido({ ...pedido, motivoCancelamento });
                              }
                            }}
                          >
                            Confirmar Cancelamento
                          </Button>
                        </div>
                      </form>
                    </Dialog>
                  </Modal>
                </DialogTrigger>
              </div>
            )}
          </span>
        </td>
      </tr>
    </>);
});

const PedidosSection = React.memo(({
  pedidos,
  loading,
  currentPage,
  totalPages,
  perfisClientes,
  onPageChange,
  onFilterChange,
  onAceitarPedido,
  onFinalizarPedido,
  onPedidoSaiuParaEntrega,
  onCancelarPedido,
  onSelectNumber,
  initialLoad
}: {
  pedidos: any[],
  loading: boolean,
  currentPage: number,
  totalPages: number,
  perfisClientes: Record<string, any>,
  onPageChange: (page: number) => void,
  onFilterChange: (filters: { status?: string, date?: string }) => void,
  onAceitarPedido: (pedido: any) => void,
  onFinalizarPedido: (pedido: any) => void,
  onPedidoSaiuParaEntrega: (pedido: any) => void,
  onCancelarPedido: (pedido: any) => void,
  onSelectNumber: (number: any) => void,
  initialLoad: boolean
}) => {
  const [filterStatus, setFilterStatus] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [motivoCancelamento, setMotivoCancelamento] = useState("");

  const handleFilter = () => {
    onFilterChange({
      status: filterStatus,
      date: filterDate
    });
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  if (initialLoad && loading) {
    return (
      <div className="w-full p-1">
        <div className="p-4 bg-white border-green-300 rounded-lg border shadow-md dark:bg-gray-800 dark:border-gray-700">
          <div className="space-y-4">
            <div className="space-y-3">
              <div className="ml-2 text-2xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center">Pedidos</div>
              <ContactSkeleton />
              <ContactSkeleton />
              <ContactSkeleton />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full p-1">
      <div className="p-4 bg-white border-green-300 rounded-lg border shadow-md dark:bg-gray-800 dark:border-gray-700">
        <div className="flex flex-col sm:flex-row items-center mb-3">
          <div className="ml-2 text-2xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center">Pedidos</div>
          <div className="w-full sm:w-auto sm:ml-4 flex flex-col sm:flex-row gap-2">
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="w-full sm:w-auto p-2 border rounded-lg bg-white text-gray-700 dark:bg-gray-900 dark:text-white"
            >
              <option value="">Todos os Status</option>
              <option value="pendente">Aguardando Confirmação</option>
              <option value="em_preparacao">Em Preparação</option>
              <option value="saiu_para_entrega">Saiu para Entrega</option>
              <option value="entregue">Entregue</option>
            </select>

            <input
              type="date"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
              className="w-full sm:w-auto p-2 border rounded-lg bg-white text-gray-700 dark:bg-gray-900 dark:text-white"
            />

            <button
              onClick={handleFilter}
              className="w-full sm:w-auto px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition"
            >
              Filtrar
            </button>
          </div>
        </div>
        <hr className='w-full h-[2px] shadow-xl bg-green-300 m-1' />

        {loading && initialLoad === false && (
          <div className="space-y-4">
            <div className="space-y-3">
              <ContactSkeleton />
              <ContactSkeleton />
              <ContactSkeleton />
            </div>
          </div>
        )}

        {!loading && pedidos && pedidos?.length > 0 ? (
          <>
            <div className="hidden lg:block overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-100 sticky top-0">
                  <tr>
                    <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Nº</th>
                    <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Cliente</th>
                    <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Endereço</th>
                    <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Produtos</th>
                    <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Pagamento</th>
                    <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Valor Produtos</th>
                    <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Valor Frete</th>
                    <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Valor Total</th>
                    <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Valor Troco</th>
                    <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Status</th>
                    <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Data/Hora</th>
                    <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Ações</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {pedidos.map((pedido) => (
                    <PedidoItem
                      key={pedido.id}
                      pedido={pedido}
                      perfisClientes={perfisClientes}
                      onAceitarPedido={onAceitarPedido}
                      onFinalizarPedido={onFinalizarPedido}
                      onPedidoSaiuParaEntrega={onPedidoSaiuParaEntrega}
                      onCancelarPedido={onCancelarPedido}
                      onSelectNumber={onSelectNumber}
                    />
                  ))}
                </tbody>
              </table>
              <div className="flex items-center justify-center space-x-4 mt-4 mb-4">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className="px-3 py-2 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
                >
                  Anterior
                </button>

                <span>
                  Página {currentPage} de {totalPages}
                </span>

                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="px-3 py-2 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
                >
                  Próxima
                </button>
              </div>
            </div>

            <div className="lg:hidden flex flex-col gap-4">
              {pedidos.map((pedido) => {
                const cliente = perfisClientes[pedido.telefoneCliente]?.data?.response;

                const formatCurrency = (val: number, sign: string) => {
                  const c = new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: sign,
                  }).format(val);

                  const z = new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: sign,
                  }).format(0);

                  return c !== z ? c : z;
                };

                return (<>
                  <div key={pedido.id} className="bg-gray-50 p-4 rounded-lg shadow-md">
                    <div className="flex justify-between items-center">
                      <h2 className="text-lg font-semibold text-gray-900">Pedido #{pedido.id}</h2>
                      <span className={`px-2 py-1 text-xs font-medium rounded-md ${pedido.status === "pendente" ? "text-yellow-700 bg-yellow-50" :
                        pedido.status === "cancelado" ? "text-red-700 bg-red-50" :
                          pedido.status === "entregue" ? "text-green-700 bg-green-50" :
                            pedido.status === "em_preparacao" ? "text-yellow-700 bg-yellow-50" :
                              pedido.status === "saiu_para_entrega" ? "text-yellow-700 bg-yellow-50" :
                                "text-red-700 bg-red-50"}`}>
                        {pedido.status === "pendente" ? "Aguardando Confirmação" :
                          pedido.status === "entregue" ? "Entregue" :
                            pedido.status === "em_preparacao" ? "Em Preparação" :
                              pedido.status === "saiu_para_entrega" ? "Saiu para Entrega" : "Cancelado"}
                      </span>
                    </div>
                    <p className="text-sm text-gray-500 mt-1">{new Date(pedido.created_at).toLocaleString("pt-BR")}</p>
                    <div className="flex items-center gap-2 mt-2">
                      <img src={cliente?.imgUrl || "./Anoni.jpg"} alt="" className="w-10 h-10 rounded-full" />
                      <div>
                        <p className="text-gray-900">{cliente?.pushname}</p>
                        <p className="text-gray-500">{pedido.telefoneCliente}</p>
                      </div>
                    </div>
                    <small className="text-gray-700 mt-2 text-xs ">
                      <strong>Entrega:</strong> {pedido.enderecoCliente ?? "Retirada na Loja"}
                    </small>
                    <small className="text-gray-700 mt-2 text-xs "><br />
                      <strong>Produtos:</strong> {pedido.produtos.map((produto: any) => (
                        <small key={produto.nome} className="text-xs block">{produto.quantidade}x {produto.nome} - {formatCurrency(produto.preco * produto.quantidade, "BRL")}</small>
                      ))}
                    </small>
                    <div className='flex justify-center'>
                      {pedido.status === "pendente" && (
                        <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                          <DialogTrigger>
                            <Button className="rounded-md transition">
                              <CheckCircleIcon className="w-6 h-6 text-green-300" />
                            </Button>
                            <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                              <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                                <form>
                                  <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Pedido Aceito ?</Heading>
                                  <p className="text-gray-600 mt-4">Você tem certeza que deseja aceitar este pedido?</p>
                                  <div className="mt-8 flex justify-end space-x-3">
                                    <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                                      Cancelar
                                    </Button>
                                    <Button
                                      className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                                      onPress={() => onAceitarPedido(pedido)}
                                    >
                                      Confirmar
                                    </Button>
                                  </div>
                                </form>
                              </Dialog>
                            </Modal>
                          </DialogTrigger>
                        </div>
                      )}

                      {pedido.status === "saiu_para_entrega" && (
                        <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                          <DialogTrigger>
                            <Button className="rounded-md transition">
                              <BanknotesIcon className="w-6 h-6 text-green-300" />
                            </Button>
                            <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                              <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                                <form>
                                  <Heading slot="title" className="leading-none mt-0 text-xl font-bold">O pedido foi entregue?</Heading>
                                  <p className="text-gray-600 mt-4">Você tem certeza que deseja finalizar este pedido?</p>
                                  <div className="mt-8 flex justify-end space-x-3">
                                    <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                                      Cancelar
                                    </Button>
                                    <Button
                                      className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                                      onPress={() => onFinalizarPedido(pedido)}
                                    >
                                      Confirmar
                                    </Button>
                                  </div>
                                </form>
                              </Dialog>
                            </Modal>
                          </DialogTrigger>
                        </div>
                      )}

                      {pedido.status === "em_preparacao" && (
                        <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                          <DialogTrigger>
                            <Button className="rounded-md transition">
                              <RocketLaunchIcon className="w-6 h-6 text-gray-700" />
                            </Button>
                            <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                              <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                                <form>
                                  <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Pedido Saiu Para Entrega?</Heading>
                                  <p className="text-gray-600 mt-4">Você tem certeza que deseja alterar o pedido para saindo para entrega neste pedido?</p>
                                  <div className="mt-8 flex justify-end space-x-3">
                                    <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                                      Cancelar
                                    </Button>
                                    <Button
                                      className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                                      onPress={() => onPedidoSaiuParaEntrega(pedido)}
                                    >
                                      Confirmar
                                    </Button>
                                  </div>
                                </form>
                              </Dialog>
                            </Modal>
                          </DialogTrigger>
                        </div>
                      )}

                      <div
                        className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer"
                        onClick={() => onSelectNumber(cliente)}
                      >
                        <ChatBubbleBottomCenterTextIcon className="w-6 h-6 text-gray-500" />
                      </div>

                      {(pedido.status === "pendente" || pedido.status === "saiu_para_entrega") && (
                        <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                          <DialogTrigger>
                            <Button className="rounded-md transition">
                              <XCircleIcon className="w-6 h-6 text-red-300" />
                            </Button>
                            <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm">
                              <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                                <form>
                                  <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Cancelar Pedido</Heading>
                                  <p className="text-gray-600 mt-4">Tem certeza que deseja cancelar este pedido? Essa ação não pode ser desfeita.</p>
                                  <div>
                                    <label htmlFor="marca" className="block text-md font-semibold text-gray-700 mb-1">
                                      Motivo Cancelamento:
                                    </label>
                                    <input
                                      id="marca"
                                      type="text"
                                      name="marca"
                                      value={motivoCancelamento}
                                      onChange={(e) => setMotivoCancelamento(e.target.value)}
                                      className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                    />
                                  </div>
                                  <div className="mt-8 flex justify-end space-x-3">
                                    <Button className="px-4 py-2 bg-gray-300 text-red-700 rounded-md hover:bg-gray-400 transition" slot="close">
                                      Cancelar
                                    </Button>
                                    <Button
                                      className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
                                      onPress={() => {
                                        if (motivoCancelamento) {
                                          onCancelarPedido({ ...pedido, motivoCancelamento });
                                        }
                                      }}
                                    >
                                      Confirmar Cancelamento
                                    </Button>
                                  </div>
                                </form>
                              </Dialog>
                            </Modal>
                          </DialogTrigger>
                        </div>
                      )}
                    </div>
                  </div>
                </>);
              })}
              <div className="flex items-center justify-center space-x-4 mt-4">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className="px-3 py-2 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
                >
                  Anterior
                </button>

                <span>
                  Página {currentPage} de {totalPages}
                </span>

                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="px-3 py-2 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
                >
                  Próxima
                </button>
              </div>
            </div>
          </>
        ) : (
          !loading &&
          <div className="text-center py-4">
            <span className="bg-yellow-50 inline-flex rounded-lg p-3 ring-4 ring-white transition-all duration-300">
              <StorefrontOutlinedIcon sx={{ fontSize: '4rem' }} />
            </span>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Nenhum pedido registrado</h3>
            <p className="mt-1 text-sm text-gray-500">Seus pedidos e de diferentes status aparecerão aqui quando existirem.</p>
          </div>
        )}
      </div>
    </div>
  );
});

// Skeleton Components
const AtendenteSkeleton = () => (
  <div className="py-3 sm:py-1 animate-pulse">
    <div className="flex items-center space-x-4">
      <div className="flex-shrink-1">
        <div className="h-10 w-10 rounded-full bg-gray-300"></div>
      </div>
      <div className="flex-1 min-w-0">
        <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
        <div className="h-3 bg-gray-300 rounded w-1/2"></div>
      </div>
      <div className="h-5 w-5 bg-gray-300 rounded"></div>
    </div>
  </div>
);

const ContactSkeleton = () => (
  <div className="py-3 sm:py-1 animate-pulse">
    <div className="flex items-center space-x-4">
      <div className="flex-shrink-0">
        <div className="h-12 w-12 rounded-full bg-gray-300"></div>
      </div>
      <div className="flex-1 min-w-0">
        <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
        <div className="h-3 bg-gray-300 rounded w-1/2 mb-1"></div>
        <div className="h-3 bg-gray-300 rounded w-3/4"></div>
      </div>
      <div className="h-5 w-5 bg-gray-300 rounded"></div>
    </div>
  </div>
);

const MenuSkeleton = () => (
  <div className="animate-pulse">
    <div className="h-8 bg-gray-300 rounded w-full mb-2"></div>
    <div className="h-6 bg-gray-300 rounded w-3/4 mb-1"></div>
    <div className="h-6 bg-gray-300 rounded w-1/2"></div>
  </div>
);

// Updated Components with Skeleton Loading
const AtendentesSection = React.memo(({ profiles, onSelectNumber, initialLoad }: {
  profiles: ProfilesData,
  onSelectNumber: (number: any) => void,
  initialLoad: boolean
}) => {
  if (initialLoad && getStorage('cache_atendentes') === undefined && profiles.atendentes !== undefined && profiles.atendentes?.length === 0) {
    return (
      <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">
        <div className="d-block border-green-300 p-4 max-w bg-white rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full overflow-auto" style={{ maxHeight: '360px' }}>
          <div className="space-y-4">
            <div className="ml-2 text-2xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center">Atendentes Online</div>
            <hr className='w-[80%] justify-self-center h-[2px] bg-green-300 m-1' />
            <div className="space-y-3">
              <AtendenteSkeleton />
              <AtendenteSkeleton />
              <AtendenteSkeleton />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">
      <div className="d-block border-green-300 p-4 max-w bg-white rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full overflow-auto" style={{ maxHeight: '360px' }}>
        <div className="ml-2 text-2xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center">Atendentes Online</div>
        <hr className='w-[80%] justify-self-center h-[2px] bg-green-300 m-1' />
        {cookies.get('user')?.roles[0]?.name !== 'atendente' && profiles.atendentes !== undefined && profiles.atendentes?.length > 0 ? (
          <>
            <ul className="mt-1 px-2 py-2 transition-max-height duration-300">
              {profiles.atendentes.map((r: Profile) => (
                <li key={r.id} className="py-3 sm:py-1">
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-1">
                      <span className={`absolute mb-10 h-3 w-3 rounded-full ring-2 ring-white ${r.online ? 'bg-green-500' : 'bg-red-500'}`}></span>
                      <img className="inline-flex h-10 w-10 rounded-full object-cover ring-2 ring-white" src={r.img || "./Anoni.jpg"} alt={r.nome} />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        {r.nome}
                      </p>
                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                        {r.telefone}
                      </p>
                    </div>
                    <div
                      className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white cursor-pointer"
                      onClick={() => onSelectNumber(r.telefone)}
                    >
                      <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 13.4811 3.09753 14.8788 3.7148 16.1181C3.96254 16.6155 4.05794 17.2103 3.90163 17.7945L3.30602 20.0205C3.19663 20.4293 3.57066 20.8034 3.97949 20.694L6.20553 20.0984C6.78973 19.9421 7.38451 20.0375 7.88191 20.2852C9.12121 20.9025 10.5189 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.2817 22.75 8.65552 22.3463 7.21315 21.6279C6.99791 21.5207 6.77814 21.4979 6.59324 21.5474L4.3672 22.143C2.84337 22.5507 1.44927 21.1566 1.857 19.6328L2.4526 17.4068C2.50208 17.2219 2.47933 17.0021 2.37213 16.7869C1.65371 15.3445 1.25 13.7183 1.25 12ZM7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z" fill="#1C274C" />
                      </svg>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : profiles.atendentes !== undefined && profiles.atendentes?.length === 0 && (
          <>
            <div className="text-center py-4 mt-4">
              <span className="bg-[#faf5ff] inline-flex rounded-lg p-3 ring-4 ring-white transition-all duration-300">
                <IconUsersGroup className='w-16 h-16 justify-self-center' />
              </span>
              <h3 className="mt-2 text-sm font-medium text-gray-900">Nenhum atendente online</h3>
              <p className="mt-1 text-sm text-gray-500">Não há atendentes disponíveis no momento.</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

const ClientesSection = React.memo(({ profiles, onSelectNumber, initialLoad }: {
  profiles: ProfilesData,
  onSelectNumber: (number: any) => void,
  initialLoad: boolean
}) => {
  if (initialLoad && getStorage('clientes') === undefined && profiles.clientes !== undefined && profiles.clientes?.length === 0) {
    return (
      <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">
        <div className="d-block p-4 max-w bg-white border-green-300 rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full" style={{ maxHeight: '360px' }}>
          <div className="space-y-4">
            <div className="ml-2 text-2xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center">Clientes</div>
            <hr className='w-[80%] justify-self-center h-[2px] bg-green-300 m-1' />
            <div className="space-y-3">
              <ContactSkeleton />
              <ContactSkeleton />
              <ContactSkeleton />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">
      <div className="d-block p-4 max-w bg-white border-green-300 rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full" style={{ maxHeight: '360px' }}>
        <div className="ml-2 text-2xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center">Clientes</div>
        <hr className='w-[80%] justify-self-center h-[2px] bg-green-300 m-1' />

        {profiles.clientes !== undefined && profiles.clientes?.length === 0 ? (
          <div className="text-center py-4 mt-4">
            <span className="bg-orange-50 inline-flex rounded-lg p-3 ring-4 ring-white transition-all duration-300">
              <AddReactionOutlinedIcon sx={{ fontSize: '4rem' }} />
            </span>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Nenhum cliente cadastrado</h3>
            <p className="mt-1 text-sm text-gray-500">Não há clientes disponíveis no momento.</p>
          </div>
        ) : (
          <ul className="mt-1 px-2 py-2 transition-max-height duration-300">
            {profiles.clientes !== undefined && profiles.clientes?.length > 0 && profiles.clientes.map((cliente: Profile) => (
              <ContactItem
                key={cliente.id || cliente.telefone}
                contact={cliente}
                onClick={() => onSelectNumber(cliente.telefone)}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
});

const ConversasSection = React.memo(({ contatos, onSelectNumber, initialLoad }: { contatos: any[], onSelectNumber: (number: any) => void, initialLoad: boolean }) => {
  const [loading, setLoading] = useState(true);
  const formatLastMessageTime = useCallback((unixTimestamp: any) => {
    const date = new Date(unixTimestamp * 1000);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };

    return date.toLocaleString('pt-BR', options);
  }, []);

  if (initialLoad && contatos === undefined) {
    return (
      <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">
        <div className="d-block p-4 max-w bg-white border-green-300 rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full" style={{ maxHeight: '360px' }}>
          <div className="space-y-4">
            <div className="ml-4 text-2xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center">Últimas Conversas</div>
            <hr className='w-[80%] justify-self-center h-[2px] bg-green-300 m-1' />
            <div className="space-y-3">
              <ContactSkeleton />
              <ContactSkeleton />
              <ContactSkeleton />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">
      <div className="d-block p-4 max-w bg-white border-green-300 rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full" style={{ maxHeight: '360px' }}>
        <div className="ml-4 text-2xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center">Últimas Conversas</div>
        <hr className='w-[80%] justify-self-center h-[2px] bg-green-300 m-1' />
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700 p-2 mr-0 scroll-m-2" style={{ maxHeight: '300px' }}>
          {!contatos || contatos?.length === 0 ? (
            <div className="text-center py-4">
              <span className="bg-blue-50 inline-flex rounded-lg p-3 ring-4 ring-white transition-all duration-300">
                <IconMessage2X className='w-16 h-16 justify-self-center' />
              </span>
              <h3 className="mt-2 text-sm font-medium text-gray-900">Nenhuma conversa salva</h3>
              <p className="mt-1 text-sm text-gray-500">Suas conversas aparecerão aqui quando existirem.</p>
            </div>
          ) : (
            <List
              height={290}
              itemCount={contatos?.length}
              itemSize={80}
              width="100%"
            >
              {({ index, style }) => {
                const r = contatos[index];
                if (r['jid']?.split('@')[0] === "0") return null;
                return (
                  <div style={style}>
                    <li className="py-3 sm:py-1">
                      <div className="flex items-center space-x-4 mr-4">
                        <div className="flex-shrink-0">
                          <img
                            className="h-12 w-12 rounded-full"
                            src={r?.imgUrl || "./Anoni.jpg"}
                            alt="Avatar"
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white uppercase">
                            {r?.name || "Anônimo"}
                          </p>
                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                            {r.unread === 0
                              ? "Nenhuma mensagem não lida"
                              : r.unread === 1
                                ? "1 mensagem não lida"
                                : `${r.unread} mensagens não lidas`}
                          </p>
                          <p className="text-xs text-gray-500 truncate dark:text-gray-400">
                            Última mensagem <br /> {formatLastMessageTime(r.lastMessageTime)}
                          </p>
                        </div>
                        <div
                          className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white cursor-pointer"
                          onClick={() => onSelectNumber(r)}
                        >
                          <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 13.4811 3.09753 14.8788 3.7148 16.1181C3.96254 16.6155 4.05794 17.2103 3.90163 17.7945L3.30602 20.0205C3.19663 20.4293 3.57066 20.8034 3.97949 20.694L6.20553 20.0984C6.78973 19.9421 7.38451 20.0375 7.88191 20.2852C9.12121 20.9025 10.5189 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.2817 22.75 8.65552 22.3463 7.21315 21.6279C6.99791 21.5207 6.77814 21.4979 6.59324 21.5474L4.3672 22.143C2.84337 22.5507 1.44927 21.1566 1.857 19.6328L2.4526 17.4068C2.50208 17.2219 2.47933 17.0021 2.37213 16.7869C1.65371 15.3445 1.25 13.7183 1.25 12ZM7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z" fill="#1C274C" />
                          </svg>
                        </div>
                      </div>
                    </li>
                  </div>
                );
              }}
            </List>
          )}
        </ul>
      </div>
    </div>
  );
});

const FilaAtendimentoSection = React.memo(({ filaEspera, contatos, onSelectNumber, initialLoad }: {
  filaEspera: any[],
  contatos: any[],
  onSelectNumber: (number: any) => void,
  initialLoad: boolean
}) => {
  const handleToggle = (event: any) => {
    const targetId = event.currentTarget.getAttribute('data-target');
    const submenu = document.getElementById(targetId);
    const icon = event.currentTarget.querySelector('.menu-icon');

    if (submenu) {
      submenu.classList.toggle('max-h-0');
      submenu.classList.toggle('max-h-screen');
      submenu.classList.toggle('overflow-hidden');
    }

    if (icon) {
      icon.classList.toggle('rotate-180');
    }
  };

  const contatosPorCategoria = useMemo(() => {
    return userInstance?.[0]?.categoria_atendente?.reduce((acc: Record<string, number>, categoria: any) => {
      if (filaEspera && filaEspera?.length > 0 && categoria.id) {
        acc[categoria.id] = filaEspera.filter((fila: any) => fila.codCategoria === categoria.id)?.length;
      }
      return acc;
    }, {});
  }, [userInstance, filaEspera]);

  const formatLastMessageTime = useCallback((unixTimestamp: any) => {
    const date = new Date(unixTimestamp * 1000);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };
    return date.toLocaleString('pt-BR', options);
  }, []);

  if (cookies.get('user')?.roles[0]?.name === 'atendente') {
    return null;
  }

  if (initialLoad && getStorage('cache_filaEspera') === undefined && filaEspera?.length === 0) {
    return (
      <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">
        <div className="d-block border-green-300 p-4 max-w bg-white rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full overflow-auto" style={{ maxHeight: '360px' }}>
          <div className="space-y-4">
            <div className="ml-4 text-2xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center">Fila de Atendimento</div>
            <hr className='w-[80%] justify-self-center h-[2px] bg-green-300 m-1' />

            <div className="space-y-3">
              <ContactSkeleton />
              <ContactSkeleton />
            </div>
            <div className="space-y-3">
              <ContactSkeleton />
              <ContactSkeleton />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (<>
    <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">
      <div className="d-block border-green-300 p-4 max-w bg-white rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full overflow-auto" style={{ maxHeight: '360px' }}>
        <div className="ml-4 text-2xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center">Fila de Atendimento</div>
        <hr className='w-[80%] justify-self-center h-[2px] bg-green-300 m-1' />
        <div className="flow-root">
          <ul role="list" className="-mx-2 space-y-1">
            <li>
              <div>
                <ul className="mt-1 px-2 max-h-screen overflow-hidden transition-max-height duration-300" id="menu-1">
                  {cookies.get('instancias')?.[0]?.categoria_atendente?.length === 0 ?
                    <>
                      <div className="text-center py-4">
                        <span className="bg-green-50 inline-flex rounded-lg p-3 ring-4 ring-white transition-all duration-300">
                          <SupportAgentIcon sx={{ fontSize: '4rem' }} />
                        </span>
                        <h3 className="mt-2 text-sm font-medium text-gray-900">Nenhum departamento registrado</h3>
                        <p className="mt-1 text-sm text-gray-500">Seus departamentos e os atendimentos aparecerão aqui quando existirem.</p>
                      </div>
                    </>
                    :
                    cookies.get('instancias')?.[0]?.categoria_atendente?.map((categoria: any, key: any) => (<>
                      <li key={key}>
                        <button
                          type="button"
                          className="flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                          aria-controls={`sub-menu-${key}`}
                          aria-expanded="true"
                          data-target={`sub-menu-${key}`}
                          onClick={handleToggle}
                        >
                          {categoria.setor}
                          <span className="transform -translate-y-2/2 -translate-x-2/2">
                            <div className="flex w-4 h-4 mt-2 bg-green-400 rounded-full items-center justify-center text-white text-center text-md">
                              {contatosPorCategoria?.[categoria.id] || 0}
                            </div>
                          </span>
                          <svg
                            className="ml-auto h-5 w-5 shrink-0 text-gray-400 transition-transform duration-300 menu-icon"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <ul
                          className="mt-1 px-2 max-h-0 overflow-hidden transition-max-height duration-300 ease-in-out"
                          id={`sub-menu-${key}`}
                        >
                          {filaEspera?.length > 0 && filaEspera.map((fila: any) => {
                            const contato = contatos.find((r: any) => r['jid']?.split('@')[0] === fila.telefoneCliente);
                            if (contato && categoria.id === fila.codCategoria) {
                              return (
                                <li key={contato['jid']} className="py-3 sm:py-1">
                                  <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                      <img
                                        className="h-12 w-12 rounded-full"
                                        src={contato?.imgUrl || "./Anoni.jpg"}
                                        alt="Avatar"
                                      />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        {contato?.name}
                                      </p>
                                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {contato.unread} unanswered messages.
                                      </p>
                                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        Last Message <br /> {formatLastMessageTime(contato.lastMessageTime)}
                                      </p>
                                    </div>
                                    <div
                                      className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white cursor-pointer"
                                      onClick={() => onSelectNumber(contato)}
                                    >
                                      <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 13.4811 3.09753 14.8788 3.7148 16.1181C3.96254 16.6155 4.05794 17.2103 3.90163 17.7945L3.30602 20.0205C3.19663 20.4293 3.57066 20.8034 3.97949 20.694L6.20553 20.0984C6.78973 19.9421 7.38451 20.0375 7.88191 20.2852C9.12121 20.9025 10.5189 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.2817 22.75 8.65552 22.3463 7.21315 21.6279C6.99791 21.5207 6.77814 21.4979 6.59324 21.5474L4.3672 22.143C2.84337 22.5507 1.44927 21.1566 1.857 19.6328L2.4526 17.4068C2.50208 17.2219 2.47933 17.0021 2.37213 16.7869C1.65371 15.3445 1.25 13.7183 1.25 12ZM7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z" fill="#1C274C" />
                                      </svg>
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                            return null;
                          })}
                        </ul>
                      </li>
                    </>))}
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </>);
});

// Funções utilitárias
const decompressCache = new Map();

const decompressAndDecode = (value: string) => {
  if (decompressCache.has(value)) {
    return decompressCache.get(value);
  }

  try {
    console.log('Valor inicial (Base64):', value);
    const binaryString = atob(value);
    console.log('Valor decodificado:', binaryString);
    const byteArray = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
    console.log('Array de bytes:', byteArray);
    const decodedString = Pako.inflate(byteArray, { to: 'string' });

    if (decodedString.startsWith('{') || decodedString.startsWith('[')) {
      console.log('JSON detectado:', decodedString);
      const result = JSON.parse(decodedString);
      decompressCache.set(value, result);
      return result;
    }
    throw new Error('String descompactada não é JSON válida');
  } catch (error) {
    console.error('Erro ao descomprimir o valor:', error);
    return null;
  }
};

const wrapText = (text: string, maxLineLength: number): string => {
  let wrappedText = '';
  let currentLineLength = 0;

  for (let i = 0; i < text?.length; i++) {
    if (currentLineLength >= maxLineLength && text[i] !== '\n') {
      wrappedText += '\n';
      currentLineLength = 0;
    }
    wrappedText += text[i];
    currentLineLength++;

    if (text[i] === '\n') {
      currentLineLength = 0;
    }
  }

  return wrappedText;
};

const formatCurrency = (val: number, sign: string) => {
  const c = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: sign,
  }).format(val);

  const z = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: sign,
  }).format(0);

  return c !== z ? c : z;
};

// Componente principal
function Home() {
  const [motivoCancelamento, setMotivoCancelamento] = useState("");

  const [state, setState] = useState<HomeState>({
    infoInstance: [{}],
    profiles: {
      atendentes: [],
      clientes: []
    },
    atendentes: [],
    clientes: [],
    filaEspera: [],
    mensagens: {
      response: "",
      responseOriginal: "",
      inicio: "",
      fim: ""
    },
    pedidos: {
      data: [],
      currentPage: 1,
      totalPages: 1,
      loading: true,
      filterStatus: "",
      filterDate: ""
    },
    contatos: [],
    perfisClientes: {},
    showChat: false,
    selectedContact: null,
    selectedContactImage: "",
    selectedContactName: "",
    conversas: {},
    imgPerfil: "",
    motivoCancelamento: "",
    editing: 'response',
    initialLoad: true,
    cachedDataLoaded: false
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Funções de manipulação de estado
  const updateState = (newState: Partial<HomeState>) => {
    setState(prev => ({ ...prev, ...newState }));
  };

  const updatePedidos = (newPedidos: Partial<HomeState['pedidos']>) => {
    setState(prev => ({
      ...prev,
      pedidos: {
        ...prev.pedidos,
        ...newPedidos
      }
    }));
  };

  // Efeitos
  useEffect(() => {
    StoreApi.listCliente().then((r) => {
      const compressedData = compressAndEncode(r);
      cookies.set('clientes', compressedData);
    }).catch((error: any) => {
      console.error('Erro ao buscar clientes:', error);
    });
    initData();

    const intervalo = setInterval(pollStatus, 90000);
    return () => clearInterval(intervalo);
  }, []);

  useEffect(() => {
    if (state.pedidos.data?.length > 0) {
      carregarPerfis();
    }
  }, [state.pedidos.data]);

  // Funções principais
  const initData = async () => {
    try {
      // Tentar usar cache primeiro
      const cachedAtendentes = getStorage(LOCAL_STORAGE_KEYS.atendentes);
      const cachedClientes = getStorage(LOCAL_STORAGE_KEYS.clientes);
      const cachedContatos = getStorage(LOCAL_STORAGE_KEYS.contatos);
      const cachedPedidos = getStorage(LOCAL_STORAGE_KEYS.pedidos);
      const cachedFila = getStorage(LOCAL_STORAGE_KEYS.filaEspera);

      if (cachedAtendentes) updateState({ atendentes: cachedAtendentes });
      if (cachedClientes) updateState({ clientes: cachedClientes });
      if (cachedContatos) updateState({ contatos: cachedContatos });
      if (cachedPedidos) updatePedidos({ data: cachedPedidos.data, currentPage: 1, totalPages: 1, loading: false });
      if (cachedFila) updateState({ filaEspera: cachedFila });

      updateState({ cachedDataLoaded: true });

      // Buscar dados reais
      const atendentes = await getAtendentesFromCookies();
      updateState({
        profiles: {
          ...state.profiles,
          atendentes
        },
        atendentes
      });
      setStorage(LOCAL_STORAGE_KEYS.atendentes, atendentes);

      const cookiesData = cookies.get('clientes');
      if (cookiesData) {
        const parsedData = decompressAndDecode(cookiesData);
        updateState({
          clientes: parsedData || [],
          profiles: {
            ...state.profiles,
            clientes: parsedData || []
          }
        });
        setStorage(LOCAL_STORAGE_KEYS.clientes, parsedData || []);
      }

      await fetchProfiles();

      const contatosResponse = await chatProApi.listChats();
      if (contatosResponse?.data?.response?.length > 0) {
        const filteredContacts = contatosResponse?.data.response.filter((r: any) => r['jid']?.split('@')[0] !== "0");
        updateState({ contatos: filteredContacts });
        setStorage(LOCAL_STORAGE_KEYS.contatos, filteredContacts);

        const firstContact = filteredContacts[0];
        const contactNumber = firstContact['jid']?.split('@')[0];
        updateState({ selectedContact: contactNumber });

        const profileResponse = await chatProApi.getProfile({ number: contactNumber });
        if (profileResponse?.data?.response) {
          updateState({
            selectedContactName: profileResponse.data.response.name || "",
            selectedContactImage: profileResponse?.data.response.imgUrl || ""
          });
        }

        const user = cookies.get('user');
        const userId = user?.roles[0]?.name === 'atendente' ? user.instances_cliente[0].codUserCliente : user?.id;
        const userResponse = await UserApi.showUser(userId);
        if (userResponse.data.user_instance) {
          updateState({ conversas: userResponse.data.user_instance[0]?.user_instance_web_hook });
        }
      }

      await fetchPedidos(); // já atualiza o cache

      if (userInstance && userInstance?.length > 0) {
        const filaResponse = await chatConfigApi.getFilaEspera({
          filters: {
            codUserInstance: userInstance[0].id
          }
        });
        updateState({ filaEspera: filaResponse });
        setStorage(LOCAL_STORAGE_KEYS.filaEspera, filaResponse);
      }

      updateState({ initialLoad: false });
    } catch (error) {
      console.error("Erro ao inicializar dados:", error);
      updateState({ initialLoad: false });
    }
  };

  const getAtendentesFromCookies = async () => {
    try {
      const cookieData = cookies.get('atendentes');
      if (!cookieData) return [];

      const compressed = Uint8Array.from(atob(cookieData), c => c.charCodeAt(0));
      const jsonString = new TextDecoder().decode(Pako.inflate(compressed));
      const rawAtendentes = JSON.parse(jsonString) || [];

      return await Promise.all(
        rawAtendentes.map(async (a: any) => {
          try {
            const response = await ChatProApi.getProfile({ number: "55" + a.telefone });
            return {
              ...a,
              img: response?.data?.response?.imgUrl || "./Anoni.jpg",
              telefone: a.telefone
            };
          } catch (error) {
            console.error(`Erro ao buscar perfil do atendente ${a.telefone}:`, error);
            return {
              ...a,
              img: "./Anoni.jpg",
              telefone: a.telefone
            };
          }
        })
      );
    } catch (error) {
      console.error("Erro ao decodificar atendentes:", error);
      return [];
    }
  };

  const pollStatus = async () => {
    if (cookies.get('user') === undefined) return;

    try {
      const status = await chatProApi.getStatus();
      if (status?.data?.response?.connected) {
        const phone = status.data.response.info['Wid'].split('@');

        const profileResponse = await chatProApi.getProfile({ number: phone[0] });
        if (profileResponse?.data?.response) {
          updateState({ imgPerfil: profileResponse.data.response['imgUrl'] || "" });
        }

        const contatosResponse = await chatProApi.listChats();
        if (contatosResponse?.data?.response?.length > 0) {
          // Filtra contatos que atendem à regra específica
          const filteredContacts = contatosResponse?.data.response.filter(
            (r: any) => r['jid']?.split('@')[0] !== "0"
          );

          if (filteredContacts?.length > 0) {
            updateState({
              contatos: filteredContacts,
              selectedContact: filteredContacts[0]['jid']?.split('@')[0],
            });

            const firstContact = filteredContacts[0];
            const contactProfile = await chatProApi.getProfile({
              number: firstContact['jid']?.split('@')[0],
            });

            if (contactProfile?.data?.response) {
              updateState({
                selectedContactName: contactProfile.data.response.name || "",
                selectedContactImage: contactProfile.data.response.imgUrl || "",
              });
            }
          }
        }
      }
    } catch (error) {
      console.error("Erro ao verificar status:", error);
    }
  };

  const getClientesFromCookies = async () => {
    try {
      const cookieData = cookies.get('clientes');
      if (!cookieData) return [];

      const parsedData = decompressAndDecode(cookieData);
      if (!parsedData) return [];

      return await Promise.all(
        parsedData.map(async (cliente: any) => {
          try {
            const response = await ChatProApi.getProfile({ number: "55" + (cliente.celular || cliente.telefone) });
            return {
              ...cliente,
              img: response?.data?.response?.imgUrl || "./Anoni.jpg",
              telefone: cliente.celular || cliente.telefone,
              name: cliente.nome || cliente.nomeRazaoSocial || cliente.pushname
            };
          } catch (error) {
            console.error(`Erro ao buscar perfil do cliente ${cliente.celular}:`, error);
            return {
              ...cliente,
              img: "./Anoni.jpg",
              telefone: cliente.celular || cliente.telefone,
              name: cliente.nome || cliente.nomeRazaoSocial || cliente.pushname
            };
          }
        })
      );
    } catch (error) {
      console.error("Erro ao decodificar clientes:", error);
      return [];
    }
  };

  const fetchProfiles = async () => {
    try {
      const atendentes = await getAtendentesFromCookies();
      const clientes = await getClientesFromCookies();

      updateState({
        profiles: {
          atendentes,
          clientes
        },
        clientes // Mantém também no estado raiz para compatibilidade
      });
    } catch (error) {
      console.error("Erro ao buscar perfis:", error);
      updateState({
        profiles: {
          atendentes: [],
          clientes: []
        },
        clientes: []
      });
    }
  };

  const carregarPerfis = async () => {
    updatePedidos({ loading: true });

    const telefones = state.pedidos.data.map(p => p.telefoneCliente);
    const perfis: Record<string, any> = {};

    try {
      const batchSize = 5;
      for (let i = 0; i < telefones?.length; i += batchSize) {
        const batch = telefones.slice(i, i + batchSize);
        const responses = await Promise.all(
          batch.map(tel =>
            chatProApi.getProfile({ number: "55" + tel }).catch(() => null)
          )
        );

        responses.forEach((res, index) => {
          if (res?.data?.response) {
            perfis[batch[index]] = res;
          }
        });
      }

      updateState({ perfisClientes: perfis });
    } catch (error) {
      console.error("Erro ao carregar perfis:", error);
    } finally {
      updatePedidos({ loading: false });
    }
  };

  const fetchPedidos = async (page = 1) => {
    updatePedidos({ loading: true });

    const user = cookies.get("user");
    if (!user) {
      updatePedidos({ loading: false });
      return;
    }

    let filters: Record<string, any> = {};

    if (user?.roles?.[0]?.name === "atendente") {
      filters.codStoreEmpresa = {
        filterType: "EQUALS",
        filterValue: user?.data?.instances_cliente?.[0]?.id
      };
    } else if (user?.data?.user_instance?.[0]?.id) {
      filters.codStoreEmpresa = {
        filterType: "EQUALS",
        filterValue: user?.data?.user_instance?.[0]?.id
      };
    }

    if (state.pedidos.filterStatus) {
      filters["status"] = {
        filterType: "EQUALS",
        filterValue: state.pedidos.filterStatus
      };
    }

    if (state.pedidos.filterDate) {
      filters["created_at"] = {
        filterType: "ILIKE",
        filterValue: `${state.pedidos.filterDate}%`
      };
    }

    const orderBy = { id: "desc" };

    try {
      const response = await StoreApi.listStorePedido(
        paginate,
        filters,
        orderBy,
        page
      );

      if (response?.data?.data) {
        updatePedidos({
          data: response.data.data || [],
          currentPage: response.data.current_page,
          totalPages: response.data.last_page,
          loading: false
        });
        setStorage(LOCAL_STORAGE_KEYS.pedidos, { data: response.data.data });
      }
    } catch (error) {
      console.error("Erro ao buscar pedidos:", error);
      updatePedidos({
        data: [],
        loading: false
      });
    }
  };

  // Handlers
  const handlePrevPage = () => {
    if (state.pedidos.currentPage > 1) {
      const newPage = state.pedidos.currentPage - 1;
      updatePedidos({ currentPage: newPage });
      fetchPedidos(newPage);
    }
  };

  const handleNextPage = () => {
    if (state.pedidos.currentPage < state.pedidos.totalPages) {
      const newPage = state.pedidos.currentPage + 1;
      updatePedidos({ currentPage: newPage });
      fetchPedidos(newPage);
    }
  };

  const handleFilterChange = (filters: { status?: string, date?: string }) => {
    updatePedidos({
      filterStatus: filters.status || "",
      filterDate: filters.date || "",
      currentPage: 1
    });
    fetchPedidos(1);
  };

  const handleFinalizarPedido = async (pedido: any) => {
    try {
      const bodyPedido = { ...pedido, status: 'entregue' };
      await StoreApi.updateStorePedido(bodyPedido.id, bodyPedido);
      await fetchPedidos(state.pedidos.currentPage);
    } catch (error) {
      console.error("Erro ao finalizar pedido:", error);
    }
  };

  const handleAceitarPedido = async (pedido: any) => {
    try {
      const bodyPedido = { ...pedido, status: 'em_preparacao' };
      await StoreApi.updateStorePedido(bodyPedido.id, bodyPedido);
      await fetchPedidos(state.pedidos.currentPage);
    } catch (error) {
      console.error("Erro ao aceitar pedido:", error);
    }
  };

  const handleCancelarPedido = async (pedido: any) => {
    try {
      const bodyPedido = {
        ...pedido,
        status: 'cancelado',
        motivoCancelamento: state.motivoCancelamento
      };
      await StoreApi.updateStorePedido(bodyPedido.id, bodyPedido);
      updateState({ motivoCancelamento: "" });
      await fetchPedidos(state.pedidos.currentPage);
    } catch (error) {
      console.error("Erro ao cancelar pedido:", error);
    }
  };

  const handlePedidoSaiuParaEntrega = async (pedido: any) => {
    try {
      const bodyPedido = { ...pedido, status: 'saiu_para_entrega' };
      await StoreApi.updateStorePedido(bodyPedido.id, bodyPedido);
      await fetchPedidos(state.pedidos.currentPage);
    } catch (error) {
      console.error("Erro ao atualizar pedido para saiu para entrega:", error);
    }
  };

  const handleSelectNumber = (number: any) => {
    const numberToSelect = number['jid'] !== undefined ? number['jid'].split('@')[0] : number;

    chatProApi.getProfile({ 'number': numberToSelect }).then((r: any) => {
      updateState({
        selectedContact: numberToSelect,
        selectedContactName: r.data.response?.name || "",
        selectedContactImage: r.data.response?.imgUrl || "",
        showChat: true
      });

      const bottomScroll = document.getElementById("scrollableDiv");
      if (bottomScroll) {
        bottomScroll.scrollTop = bottomScroll.scrollHeight;
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const originalText = event.target.value;
    const wrappedText = wrapText(originalText, 32);
    updateState({
      mensagens: {
        ...state.mensagens,
        response: wrappedText,
        responseOriginal: originalText
      }
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const field = state.editing;
      const newValue = state.mensagens[field] + '\n';
      const wrappedValue = wrapText(newValue, 32);

      updateState({
        mensagens: {
          ...state.mensagens,
          [field]: wrappedValue
        }
      });
    }
  };

  const onEmojiSelectResponse = (emoji: any) => {
    updateState({
      mensagens: {
        ...state.mensagens,
        response: state.mensagens.response + emoji
      }
    });
  };

  const renderConversation = (conversation: any) => {
    return conversation.split('\n\n').map((text: any, index: any, array: any) => (
      <React.Fragment key={index}>
        <p className="text-sm mt-1">{text}</p>
        {index < array?.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div className="w-full h-auto">
        <div className="h-8 xml:h-28 bg-green-500" />

        <div className="flex flex-col bg-gray-50 sm:flex-row border border-grey rounded shadow-lg mb-10 m-4 h-auto">
          <div className="w-full bg-gray-50 border-md sm:border-md flex flex-col">
            <div className="py-2 bg-gray-50 px-4 bg-grey-lighter flex flex-col w-full">
              <div className="flex flex-wrap md:m-2">
                <AtendentesSection
                  profiles={state.profiles}
                  onSelectNumber={handleSelectNumber}
                  initialLoad={state.initialLoad}
                />

                <ClientesSection
                  profiles={state.profiles}
                  onSelectNumber={handleSelectNumber}
                  initialLoad={state.initialLoad}
                />

                <ConversasSection
                  contatos={state.contatos}
                  onSelectNumber={handleSelectNumber}
                  initialLoad={state.initialLoad}
                />

                <FilaAtendimentoSection
                  filaEspera={state.filaEspera}
                  contatos={state.contatos}
                  onSelectNumber={handleSelectNumber}
                  initialLoad={state.initialLoad}
                />

                <PedidosSection
                  pedidos={state.pedidos.data}
                  loading={state.pedidos.loading}
                  currentPage={state.pedidos.currentPage}
                  totalPages={state.pedidos.totalPages}
                  perfisClientes={state.perfisClientes}
                  onPageChange={(page) => {
                    updatePedidos({ currentPage: page });
                    fetchPedidos(page);
                  }}
                  onFilterChange={handleFilterChange}
                  onAceitarPedido={handleAceitarPedido}
                  onFinalizarPedido={handleFinalizarPedido}
                  onPedidoSaiuParaEntrega={handlePedidoSaiuParaEntrega}
                  onCancelarPedido={handleCancelarPedido}
                  onSelectNumber={handleSelectNumber}
                  initialLoad={state.initialLoad}
                />
              </div>
            </div>
          </div>
        </div>

        <ChatComponent
          showChat={state.showChat}
          setShowChat={(show) => updateState({ showChat: show })}
          SelectedContactImage={state.selectedContactImage}
          SelectedContactName={state.selectedContactName}
          SelectedContact={state.selectedContact || ""}
          Conversas={state.conversas}
          onEmojiSelectResponse={onEmojiSelectResponse}
          mensagemResponseOriginal={state.mensagens.responseOriginal}
          mensagemResponse={state.mensagens.response}
          handleKeyDown={handleKeyDown}
          handleChange={handleChange}
          wrapText={wrapText}
          formatLastMessageTime={(timestamp) => {
            const date = new Date(timestamp * 1000);
            return date.toLocaleString('pt-BR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            });
          }}
          renderConversation={renderConversation}
        />
      </div>
    </QueryClientProvider>
  );
}

export default Home;