import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
/* import Cookies from "universal-cookie"; */
import NavBar from './components/Navbar';
import SignIn from './views/Auth/SignIn';
import SignUp from './views/Auth/SignUp';
import Home from './views/Plans/Basic/Home';
import PainelConfig from './views/Plans/Basic/PainelConfig';
import Pricing from './views/Auth/Pricing';
import Dashboard from './views/Dashboard';
import Profile from './views/Users/ProfileSettings';
import AdminList from './views/Users/AdminList';
import UsersList from './views/Users/UsersList';
import PlansList from './views/Plans/PlansList';
import NotificationList from './views/Users/NotificationList';
import Kanban from './views/Plans/Basic/kanban';
import Modal from './components/Modal/Modal';
import Auth from './services/Auth';
import Modals from './components/Modal/Modal';
import Loading from './components/Utils/Loading';
import Cookies from 'universal-cookie';
import { Store } from './views/Plans/Basic/Store';
import StoreApi from './services/StoreApi';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Bounce, Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Payment from './views/Auth/Payment';

/* const cookies = new Cookies(); */
const rootElement = document.getElementById('root');
const cookies = new Cookies();


function LoadingBar({ ...rest }) {
  const [loading, setLoading] = useState(false);
  const toastId = useRef<string | number | null>(null);

  useEffect(() => {
    // Subscribe para receber o estado de loading
    const subscription = Loading.onLoading().subscribe((loading) => {
      if (loading) {
        setLoading(true);

        // Verifica se já existe um toast ativo antes de mostrar um novo
        if (!toast.isActive(toastId.current as string | number)) {
          toastId.current = toast.info(
            <div className="flex items-center space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 100 100"
                fill="none"
              >
                <circle
                  cx={50}
                  cy={50}
                  r={45}
                  stroke="#25D366"
                  strokeWidth={10}
                  opacity="0.2"
                />
                <circle
                  cx={50}
                  cy={50}
                  r={45}
                  stroke="#25D366"
                  strokeWidth={10}
                  strokeDasharray={283}
                  strokeDashoffset={75}
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 50 50"
                    to="360 50 50"
                    dur="0.8s"
                    repeatCount="indefinite"
                  />
                </circle>
              </svg>
              <span className="text-gray-800 font-medium">Buscando dados...</span>
            </div>,
            {
              position: "top-right",
              autoClose: 50000, // Fecha manualmente
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              theme: "light",
              style: {
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                padding: "12px 16px",
                borderRadius: "8px",
              },
            }
          );
        }
      } else {
        setLoading(false);

        // Fecha o toast se loading for false
        if (toastId.current !== null && toast.isActive(toastId.current)) {
          toast.dismiss(toastId.current);
          toastId.current = null;
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={50000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
        style={{ zIndex: 999999, position: "relative" }}
      />
    </>
  );
}
const queryClient = new QueryClient();

/* const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
if (!clientId) {
  throw new Error("Google Client ID não configurado");
} */

if (rootElement !== null) {
  createRoot(rootElement).render(
 /*    <GoogleOAuthProvider clientId={clientId}>    </GoogleOAuthProvider> */
    <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <LoadingBar />
      <div className='wrapper h-screen w-screen bg-gray-50'/*  style={{ background: "#dad3cc" }} */>
        <NavBar />
        <div className='main'/*  style={{ background: "#dad3cc" }} */>
          <PrivateRoute />
        </div>
      </div>
    </BrowserRouter>
    </QueryClientProvider>
  
  );
}

function PrivateRoute() {
  const [token, setToken] = useState(Auth.getToken());
  const [user, setUser] = useState(cookies.get('user'));
  const [userRole, setUserRole] = useState(cookies.get('user') !== undefined ? cookies.get('user').roles?.[0]?.name : '');
  const [userPermission, setUserPermission] = useState(cookies.get('user') !== undefined ? cookies.get('user').permissions?.[0]?.name : '');
  const [dynamicRoutes, setDynamicRoutes] = useState<JSX.Element[]>([]);
  const [routesLoaded, setRoutesLoaded] = useState(false); // Rastreamento do carregamento de rotas
  const navigate = useNavigate();

  useEffect(() => {
    
    const subscription = Auth.observable.onToken().subscribe((token: any) => {
      setToken(token || null);
    });

    const fetchDynamicRoutes = async () => {
      try {
        const response = await StoreApi.listStoreEmpresas();
        const routesData = response.data;

        if(response.data.isModal === undefined)
          response.data.isModal = false;
        console.log('Rotas recebidas:', routesData);

        const routes = routesData.map((route: any) => {
          if (!route.id || !route.url) {
            console.warn('Rota inválida ignorada:', route);
            return null;
          }
          return <Route key={route.id} path={`/${route.url}`} element={<Store parametros={route} />} />;
        });

        setDynamicRoutes(routes.filter(Boolean));
      } catch (error) {
        console.error('Erro ao buscar rotas dinâmicas:', error);
      } finally {
        setRoutesLoaded(true); // Marca como carregado
      }
    };

    fetchDynamicRoutes();

    return () => subscription.unsubscribe();
  }, []);

  if (!routesLoaded) {
    // Tela de carregamento enquanto as rotas dinâmicas estão sendo carregadas
    return <div></div>;
  }

  if (token === null) {
    cookies.remove('user');
    cookies.remove('token');
    cookies.remove('imgUser');
    cookies.remove('clientes');
    cookies.remove('atendentes');
    cookies.remove('instancias');
    return (
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/registrar" element={<SignUp />} />
        {dynamicRoutes}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }
    if (userRole === "cliente" || userRole === "atendente") {
      console.log(userPermission)
      switch (userPermission) {
        case "Visitante":
          return (
            <Routes>
              <Route path="/login" element={<SignIn />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/payment" element={<Payment />} />
              {dynamicRoutes}
              <Route path="*" element={<Navigate to="/pricing" />} />
            </Routes>
          );
        case "Plano Teste":
        case "Plano Basic":
          return (
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/painel" element={<PainelConfig />} />
             {/*  <Route path="/dashboard" element={<Dashboard />} /> */}
              <Route path="/notification-list" element={<NotificationList />} />
              <Route path="/profile-settings" element={<Profile />} />
              {dynamicRoutes}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          );
        default:
          return <Navigate to="/registrar" />;
      }
    } else if (userRole === "admin" || userRole === "master") {
      return (
        <Routes>
          <Route path="/" element={<Home />} />
        {/*<Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile-settings" element={<Profile />} />
          <Route path="/kanban" element={<Kanban />} /> */}
          <Route path="/adm-list" element={<AdminList />} />
          <Route path="/user-list" element={<UsersList />} />
          <Route path="/plans-list" element={<PlansList />} />
          <Route path="/notification-list" element={<NotificationList />} />
          {dynamicRoutes}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/registrar" element={<SignUp />} />
          {dynamicRoutes}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      );
    }
}


reportWebVitals();