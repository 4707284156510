
import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import SideBar from './SideBar';
import SettingsNav from './SettingsNav';
import NotificationsNav from './NotificationsNav';
import Auth from "../services/Auth";
import { useNavigate } from 'react-router-dom'; // import do hook
import Cookies from 'universal-cookie';
import ModalChat from './Modal/ModalChat';
import chatProApi from '../services/ChatProApi';
import UserService from '../services/UserApi';
import ScrollableContainer from '../components/Utils/ScrollableContainer';
import apiService from '../services/Api';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

function NavBar() {

    let navigate = useNavigate();
    const location = useLocation();
    const cookies = new Cookies();

    const [SideBarShow, setSideBarShow] = useState<boolean>(false);
    const [SettingsShow, setSettingsShow] = useState(0);
    const [NotificationsShow, setNotificationsShow] = useState(0);
    const [ChatStatus, setChatStatus] = useState(0);
    const [ImgPerfil, setImgPerfil] = useState(cookies.get("imgUser") ?? "./Anoni.jpg");
    const [user, setUser] = useState({} as any);
    const [Contatos, setContatos] = useState([] as any);
    var [nome, setNome] = useState('');

    useEffect(() => {

        const subscription = Auth.observable.onToken().subscribe((data: any) => {
            const bottomScroll = document.getElementById("scrollableDiv");

            if (cookies.get("token") !== null) {

                setNome(cookies.get("user")?.nome);
                setUser(cookies.get('user'));
            }
        });
        return subscription.unsubscribe;

    }, []);
    useEffect(() => {
        // Define a função que será chamada

        if (cookies.get("token") === undefined) {
            cookies.remove('user');
            cookies.remove('instancias');
            cookies.remove('atendentes');
        }

        if (cookies.get("token") !== null) {
            if (cookies.get("user") !== undefined) {
                if (cookies.get("token") !== null) {

                    setNome(cookies.get("user")?.nome);
                    setUser(cookies.get('user'));
                }

                if (cookies.get("user")?.permissions[0].name !== "Visitante") {
                    chatProApi.getStatus().then((status: any) => {
                        if (status?.data !== undefined)
                            if (status.data.response?.connected) {
                                chatProApi.listChats().then((contatos: any) => {
                                    setContatos(contatos.data.response);
                                    { console.log(contatos.data.response) }
                                    var phone = '55' + cookies.get('user').telefone;
                                    if(cookies.get("imgUser") === undefined)
                                    {chatProApi.getProfile({ 'number': phone }).then((r) => {
                                        if (r?.data?.response !== undefined) {
                                            setImgPerfil(r?.data?.response['imgUrl']);
                                            cookies.set("imgUser", r?.data?.response['imgUrl']);
                                        }
                                    })}
                                    setChatStatus(1);


                                });


                            }
                    });
                }



            }

        }


    }, []);




    if (!['/login', '/registrar'].includes(location.pathname)) {
        return (
            <>

                <Disclosure as="nav" className="bg-white shadow" style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                <div className="flex shrink-0 items-center">
                                    <button className="flex flex-col text-1xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-1xl md:text-1x1 lg:text-1x1 text-center" onClick={() => navigate("/")}>
                                        CHAT BR
                                    </button>
                                </div>
                                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                    {/* Current: "border-green-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                    {cookies.get('token') === undefined ?
                                        <>
                                            <a
                                                href="/registrar"
                                                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-green-300 hover:text-gray-700"
                                            >
                                                Registrar
                                            </a>
                                            <a
                                                href="/login"
                                                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-green-300 hover:text-gray-700"
                                            >
                                                Acessar
                                            </a>
                                        </>
                                        :
                                        <>
                                        { cookies.get("user")?.permissions[0].name !== "Visitante" && 
                                        <>
                                            <a
                                                href="/"
                                                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-green-300 hover:text-gray-700"
                                            >
                                                Inicio
                                            </a>
                                            <a
                                                href="/painel"
                                                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-green-300 hover:text-gray-700"
                                            >
                                                Painel de Configurações
                                            </a>
                                            </>
                                        }
                                        </>}


                                </div>
                            </div>
                            <div className="hidden sm:ml-6 md:flex sm:items-center">
                                {cookies.get('token') !== undefined &&
                                    <button onClick={() => {
                                        if (NotificationsShow === 0) {
                                            setNotificationsShow(1);
                                            setSettingsShow(0);
                                            setSideBarShow(false);
                                        }
                                        else {
                                            setNotificationsShow(0);
                                            setSettingsShow(0);
                                            setSideBarShow(false);
                                        }
                                    }} aria-expanded="false" aria-haspopup="menu" id=":r2:" className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 hover:text-gray-700" type="button">
                                        <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"> <div className="absolute w-5 h-5 mt-1 bg-blue-400 rounded-full flex items-center justify-center text-white text-1xl">2</div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="mt-2 h-8 w-8 text-gray-500">
                                                <path d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clipRule="evenodd" />
                                            </svg>
                                        </span>
                                    </button>
                                }
                                {/* Profile dropdown */}
                                {cookies.get('token') !== undefined && 
                                    <Menu as="div" className="relative hidden md:block ml-3">
                                        <div className=''>

                                            <MenuButton className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                                                <span className="absolute -inset-1.5" />
                                                <img
                                                    alt=""
                                                    src={ImgPerfil !== undefined ? ImgPerfil : "./Anoni.jpg"}
                                                    className="w-16 h-16 rounded-full"
                                                />
                                            </MenuButton>
                                        </div>
                                        <MenuItems
                                            transition
                                            className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                        >
                                            <MenuItem>
                                                <a
                                                    href="/profile-settings"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none"
                                                >
                                                    Configurações
                                                </a>
                                            </MenuItem>
                                            <MenuItem>
                                                <a
                                                    onClick={() => Auth.logout() }
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none"
                                                >
                                                    Sair
                                                </a>
                                            </MenuItem>
                                        </MenuItems>
                                    </Menu>
                                    
                                }
                            </div>
                            <div className="-mr-2 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    <Bars3Icon aria-hidden="true" className="w-10 block size-6 group-data-[open]:hidden" />
                                    <XMarkIcon aria-hidden="true" className="w-10 hidden size-6 group-data-[open]:block" />
                                </DisclosureButton>
                            </div>
                        </div>
                    </div>
                    <DisclosurePanel className="sm:hidden">
                        {cookies.get('token') !== undefined && cookies.get("user")?.permissions[0].name !== "Visitante" && 
                            <div className="space-y-1 pb-3 pt-2">
                                {/* Current: "bg-green-50 border-green-500 text-green-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                                <DisclosureButton
                                    as="a"
                                    href="/"
                                    className="block border-l-4 border-green-500 bg-green-50 py-2 pl-3 pr-4 text-base font-medium text-green-700"
                                >
                                    Inicio
                                </DisclosureButton>
                                <DisclosureButton
                                    as="a"
                                    href="/painel"
                                    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                                >
                                    Painel de Configurações
                                </DisclosureButton>

                            </div>
                        }
                        {cookies.get('token') !== undefined && 
                            <div className="border-t border-gray-200 pb-3 pt-4 sm:hidden">
                                <div className="flex">
                                    <div className="shrink-0">
                                        <img
                                            alt=""
                                            src={ImgPerfil !== undefined ? ImgPerfil : "./Anoni.jpg"}
                                            className="w-16 h-16 rounded-full"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium text-gray-800">{nome}</div>
                                        <div className="text-sm font-medium text-gray-500">{cookies.get("user")?.email}</div>
                                        <div className="text-sm font-medium text-gray-500">{cookies.get("user")?.telefone}</div>
                                    </div>
                                    <button
                                        type="button"
                                        className="relative ml-auto shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                    >
                                        <span className="absolute -inset-1.5" />
                                        <span className="sr-only">View notifications</span>
                                        {/*  <BellIcon aria-hidden="true" className="size-6" /> */}
                                    </button>
                                </div>
                                <div className="mt-3 space-y-1">
                                
                                    <DisclosureButton
                                        as="a"
                                        href="/profile-settings"
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                    >
                                        Configurações
                                    </DisclosureButton>
                                    <DisclosureButton
                                        as="a"
                                        onClick={() => Auth.logout() }
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                    >
                                        Sair
                                    </DisclosureButton>
                                </div>
                            </div>
                        }
                    </DisclosurePanel>
                </Disclosure>
                {/*  <SideBar setShow={setSideBarShow} show={SideBarShow} /> */}
                {/*  <SettingsNav show={SettingsShow} /> */}
                <NotificationsNav show={NotificationsShow} />

            </>);

    }
    else {
        return (<></>);
    }
}
export default NavBar;